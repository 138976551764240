import React, { useEffect, useRef, useState } from "react";
import {
  Collapse,
  Button,
  Checkbox,
  Col,
  Flex,
  Modal,
  Radio,
  Row,
  Space,
  Select,
  Pagination,
  Spin,
  Tag,
  Input,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import CandidateCard from "../components/CandidateCard/CandidateCard";
import {
  filterIcon,
  filterAquaIcon,
  User3,
  FreshersOnly,
  LeadingIcon,
  angleleftIcon,
  editIcon,
} from "../assets";
import CandidateLocationsModal from "../components/CandidateLocationsModal";
import CandidateDetails from "./CandidateDetails";
import SVGClose from "../assets/SVGClose";
import "../styles/CandidatePage.css";
import { get, post } from "../lib/Api";
import useMediaQuery from "../hooks/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../components/Toast/Toast";
import { ToastContainer } from "react-toastify";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const CandidatePage = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  // const { searchData } = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [genderCount, setGenderCount] = useState({
    male: 0,
    female: 0,
    other: 0,
  });
  const [pageSize, setPageSize] = useState(10);

  const [citiesDropData, setCitiesDropData] = useState([]);
  const [experienceDropData, setExperienceDropData] = useState([]);
  const [languagesDropData, setLanguagesDropData] = useState([]);
  const [searchData, setSearchData] = useState(
    location.state?.searchData || {}
  );

  const [keywordInputValue, setKeywordInputValue] = useState("");
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [currentCandidate, setCurrentCandidate] = useState(null);

  useEffect(() => {
    if (!location.state?.searchData) {
      navigate("/search-candidates");
    }
  }, []);

  const fetchData = async (page) => {
    setLoading(true);
    const data = { ...searchData, page_no: page, page_size: pageSize };
    try {
      const response = await post("candidate/list", data, "", true);
      if (response?.status === true) {
        setCandidates(response.candidate_profile_list.results);
        setTotalCandidates(response.count);
        setGenderCount(response.gender);
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCities = async () => {
    setLoading(true);
    const res = await get(`master/city-list`, {}, {}, true);
    if (res?.status === true) {
      setCitiesDropData(res?.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchExperienceDropData = async () => {
    setLoading(true);
    const res = await get(`master/experience-list`, "", {}, true);
    if (res?.status === true) {
      setExperienceDropData(res?.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchLanguagesDropData = async () => {
    setLoading(true);
    const res = await get(`master/language-list`, "", {}, true);
    if (res?.status === true) {
      setLanguagesDropData(res?.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const hasValidSearchData = (data) => {
    return Object.values(data).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== "";
    });
  };

  useEffect(() => {
    if (searchData && hasValidSearchData(searchData)) {
      fetchData(currentPage);
    }
  }, [currentPage, pageSize, searchData]);

  useEffect(() => {
    fetchCities();
    fetchExperienceDropData();
    fetchLanguagesDropData();
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(current);
  };

  const handleSelectChange = (value, option) => {
    setSearchData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleSearchTypeChange = (e) => {
    setSearchData({
      ...searchData,
      searchType: e.target.value,
    });
  };

  const handleGenderTypeChange = (e) => {
    setSearchData({
      ...searchData,
      gender: e.target.value,
    });
  };

  const handleKeyPress = (e) => {
    if (
      (e.key === "Enter" && keywordInputValue.trim() !== "") ||
      (e.key === "Tab" && keywordInputValue.trim() !== "")
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      keywordInputValue.trim() !== ""
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [keywordInputValue]);

  const handleKeywordInputChange = (e) => {
    setKeywordInputValue(e.target.value);
  };

  const handleKeywordRemove = (index) => {
    setSearchData((prevData) => {
      // if (prevData.keywords.length > 1) {
      return {
        ...prevData,
        keywords: prevData.keywords.filter((_, i) => i !== index),
      };
      // } else {
      //   Toast({ message: "At least one keyword is required", type: "error" });
      //   return prevData;
      // }
    });
  };

  const handleResetData = async () => {
    setSearchData({
      // searchType: "any",
      keywords: [],
      currentCity: [],
      experience: [],
      minSalary: "",
      maxSalary: "",
      highestEducaton: [],
      industries: [],
      Gender: "",
      languages: [],
    });
  };

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedCandidates(candidates.map((candidate) => candidate.id));
    } else {
      setSelectedCandidates([]);
    }
  };

  const handleCandidateSelectChange = (candidateId) => {
    setSelectedCandidates((prevSelected) =>
      prevSelected.includes(candidateId)
        ? prevSelected.filter((id) => id !== candidateId)
        : [...prevSelected, candidateId]
    );
  };

  const handleDownload = async () => {
    if (selectedCandidates.length === 0) {
      Toast({
        message: "Please select candidate to download report",
        type: "error",
      });
      return;
    }
    setLoading(true);
    const data = { candidate_id: selectedCandidates };

    try {
      const res = await post(`candidate/bulk-export`, data, "", true);
      if (res?.status === true) {
        // Toast({ message: "Report successfully downloaded", type: "success" });
        const element = document.createElement("a");
        element.href = baseUrl + res.file_path;
        element.download = "";
        element.click();
      } else {
        Toast({
          message: res?.message || "Upload failed",
          type: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      Toast({
        message: error.response?.message || "Upload failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const showModal = () => setIsModalOpen(true);
  const showFilterModal = () => setIsFilterModalOpen(!isFilterModalOpen);
  const handleOk = () => setIsModalOpen(false);

  const handleVisibleDetails = (candidate) => {
    setCurrentCandidate(candidate);
    setVisibleDetails(true);
  };

  const handleCloseDetails = () => {
    setVisibleDetails(false);
    setCurrentCandidate(null);
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const onCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const items = [
    {
      key: "1",
      label: "Must have Keywords",
      children: (
        <div className="checkbox-list chips">
          <Flex
            wrap
            gap="small"
            className="chips-button"
            ref={inputRef}
            // style={{ marginBottom: "10px" }}
          >
            {/* <Button type="primary" ghost icon={<CloseOutlined />}>
              Designer
            </Button>*/}

            <Input
              id="Keywords"
              placeholder="Type to search keyword"
              value={keywordInputValue}
              onChange={handleKeywordInputChange}
              onKeyDown={handleKeyPress}
            />
            <Space direction="horizontal" className="tags-wrapper">
              {searchData?.keywords?.map((val, index) => (
                <Tag
                  // closeIcon={<SVGClose />}
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    handleKeywordRemove(index);
                  }}
                  color="success"
                  key={index}
                >
                  {val}
                </Tag>
              ))}
            </Space>
          </Flex>

          {/* <Button type="link" block onClick={showModal}>
            View More
          </Button> */}
        </div>
      ),
    },
    {
      key: "2",
      label: "Current City/Area",
      children: (
        <div className="checkbox-list">
          {/* <Checkbox onChange={onCheckbox}>
            Bengaluru <small>(3469)</small>
          </Checkbox>
          <Checkbox onChange={onCheckbox}>
            Hosur <small>(8)</small>
          </Checkbox>
          <Checkbox onChange={onCheckbox}>
            Hyderabad <small>(1200)</small>
          </Checkbox>
          <Checkbox onChange={onCheckbox}>
            Pune <small>(4000)</small>
          </Checkbox>
          <Button type="link" block onClick={showModal}>
            View More
          </Button> */}
          <Select
            id="currentCity"
            mode="multiple"
            placeholder="Select a Current City"
            className="candidate-select-input"
            onChange={(e) => {
              handleSelectChange(e, { id: "currentCity" });
            }}
            // tagRender={tagRender}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={citiesDropData?.map((city) => ({
              value: city.id,
              label: city.name,
              id: "currentCity",
            }))}
            value={searchData?.currentCity || undefined}
            style={{
              height: "auto",
            }}
          />
        </div>
      ),
    },
    // {
    //   key: "3",
    //   label: "Industries",
    //   children: (
    //     <div className="checkbox-list">
    //       <Checkbox onChange={onCheckbox}>
    //         IT<small>(3469)</small>
    //       </Checkbox>
    //       <Checkbox onChange={onCheckbox}>
    //         Pharmacy <small>(8)</small>
    //       </Checkbox>
    //       <Checkbox onChange={onCheckbox}>
    //         Marketing <small>(1200)</small>
    //       </Checkbox>
    //       <Checkbox onChange={onCheckbox}>
    //         Oil & Gas <small>(4000)</small>
    //       </Checkbox>
    //       <Button type="link" block onClick={showModal}>
    //         View More
    //       </Button>
    //     </div>
    //   ),
    // },
    {
      key: "4",
      label: "Gender",
      children: (
        <div className="checkbox-list">
          <Radio.Group
            onChange={handleGenderTypeChange}
            value={searchData.gender}
          >
            <Space direction="vertical">
              <Radio value={"male"}>
                Male <small>({genderCount?.male})</small>
              </Radio>
              <Radio value={"female"}>
                Female <small>({genderCount?.female})</small>
              </Radio>
              <Radio value={"other"}>
                Other <small>({genderCount?.other})</small>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
    },
    {
      key: "5",
      label: "Experience",
      children: (
        <div className="checkbox-list experience">
          {/* <Radio.Group
            onChange={handleSearchTypeChange}
            value={searchData?.searchType}
          >
            <Radio value={"any"}>
              Any
              <img src={User3} alt="" />
            </Radio>
            <Radio value={"fresher"}>
              Freshers only <img src={FreshersOnly} alt="" />
            </Radio>
          </Radio.Group> */}

          <Select
            id="experience"
            mode="multiple"
            placeholder="Select a Experience"
            className="candidate-select-input"
            onChange={(e) => {
              handleSelectChange(e, { id: "experience" });
            }}
            // tagRender={tagRender}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={experienceDropData?.map((experience) => ({
              value: experience.id,
              label: experience.name,
              id: "experience",
            }))}
            value={searchData?.experience || undefined}
            style={{
              height: "auto",
            }}
          />
        </div>
      ),
    },
    {
      key: "6",
      label: "Languages",
      children: (
        <div className="checkbox-list experience">
          <Select
            id="languages"
            mode="multiple"
            showSearch
            className="candidate-select-input"
            placeholder="select Languages"
            onChange={(e) => {
              handleSelectChange(e, { id: "languages" });
            }}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            options={languagesDropData.map((language) => ({
              value: language.id,
              label: language.name,
              id: "languages",
            }))}
            value={searchData.languages || undefined}
            style={{
              height: "auto",
            }}
          />
        </div>
      ),
    },
    // {
    //   key: "7",
    //   label: "Departments",
    //   children: <p>{text}</p>,
    // },
    // {
    //   key: "8",
    //   label: "Age",
    //   children: <p>{text}</p>,
    // },
  ];

  const onChange = (key) => {
    console.log(key);
  };
  const mediaQuery = useMediaQuery("(max-width: 767px)");

  const formatNumber = (num) => {
    const numStr = num.toString();
    const lastThree = numStr.slice(-3);
    const otherNumbers = numStr.slice(0, -3);

    if (otherNumbers !== "") {
      const formattedOtherNumbers = otherNumbers.replace(
        /\B(?=(\d{2})+(?!\d))/g,
        ","
      );
      return formattedOtherNumbers + "," + lastThree;
    }

    return lastThree;
  };

  // const getValuesAsString = (data) => {
  //   const values = [];

  //   for (const key in data) {
  //     if (data.hasOwnProperty(key)) {
  //       const value = data[key];
  //       if (Array.isArray(value)) {
  //         value.forEach((item) => {
  //           if (item) values.push(item);
  //         });
  //       } else {
  //         if (value) values.push(value);
  //       }
  //     }
  //   }

  //   return values.join(", ");
  // };

  const handleBlacklistSuccess = (isSuccess) => {
    if (isSuccess) {
      fetchData(currentPage);
    }
  };

  return (
    <>
      <Flex justify="space-between" align="center" className="candidate-head">
        <Flex align="center">
          <Button
            type="default"
            icon={<img src={angleleftIcon} alt="icon" />}
            size="large"
            onClick={() => {
              navigate("/search-candidates");
            }}
          />

          <p>
            {/* <b>{9,05,323}</b>profiles found for<b>Marketing Executives, Any</b> */}
            <b>{totalCandidates ? formatNumber(totalCandidates) : 0}</b>
            profiles found
            {/* for
            <b>Marketing Executives, Any</b>
            <b>{getValuesAsString(searchData)}</b> */}
          </p>
        </Flex>
        {/* <Button
          type="default"
          size="large"
          icon={<img src={editIcon} alt="icon" />}
          className="modify-btn"
        >
          Modify Search
        </Button> */}
      </Flex>
      <section className="candidate">
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={9} xl={7} className="candidate-mobile">
            <div className="candidate-left">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={filterIcon} alt="" />
                  Filters
                </h3>
                <Button type="link" block onClick={handleResetData}>
                  Clear all
                </Button>
              </Flex>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </div>
          </Col>
          <Col xs={24} lg={15} xl={17}>
            <div className="candidate-right">
              {/* {mediaQuery && (
                <Flex className="mobile-show" align="center" gap="10px">
                  <label>Active in</label>
                  <Select
                    defaultValue="6 months"
                    style={{
                      width: "116px",
                      height: "40px",
                    }}
                    options={[
                      {
                        value: "1",
                        label: "1 Years",
                      },
                      {
                        value: "2",
                        label: "2 Years",
                      },
                    ]}
                  />
                </Flex>
              )} */}
              <div className="candidate-right-head">
                <Checkbox onChange={handleSelectAllChange} checked={selectAll}>
                  Select all
                </Checkbox>

                <Space wrap style={{ gap: "16px" }}>
                  {/* <Flex className="mobile-active-in">
                    <label>Active in</label>
                    <Select
                      defaultValue="6 months"
                      style={{
                        width: "100%",
                      }}
                      options={[
                        {
                          value: "1",
                          label: "1 Years",
                        },
                        {
                          value: "2",
                          label: "2 Years",
                        },
                      ]}
                    />
                  </Flex> */}
                  <Button
                    type="default"
                    size="large"
                    icon={<img src={LeadingIcon} alt="icon" />}
                    className="download-btn"
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
                </Space>
              </div>
              {loading ? (
                <Spin size="large" />
              ) : (
                candidates?.map((candidate) => (
                  <CandidateCard
                    key={candidate.id}
                    candidate={candidate}
                    handleVisibleDetails={handleVisibleDetails}
                    isSelected={selectedCandidates.includes(candidate.id)}
                    onSelectChange={() =>
                      handleCandidateSelectChange(candidate.id)
                    }
                  />
                ))
              )}
              <div className="candidate-right-head">
                <Pagination
                  current={currentPage}
                  total={totalCandidates}
                  pageSize={pageSize}
                  onChange={onPageChange}
                  onShowSizeChange={onPageSizeChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "50", "100", "500"]}
                />
              </div>
            </div>
          </Col>
        </Row>
        <button className="filter-btn btn-visible" onClick={showFilterModal}>
          <img src={filterAquaIcon} alt="" />
        </button>
        <CandidateLocationsModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
        />
        <CandidateDetails
          visibleDetails={visibleDetails}
          candidate={currentCandidate}
          onClose={handleCloseDetails}
          onBlacklistSuccess={handleBlacklistSuccess}
        />
        <div className="mobile-menu">
          {isFilterModalOpen && (
            <div
              className="candidate-details-overlay"
              onClick={() => setIsFilterModalOpen(false)}
            />
          )}
          <div className={`mobile-menu-modal ${isFilterModalOpen && "active"}`}>
            <button className="close" onClick={showFilterModal}>
              <SVGClose />
            </button>
            <div className="candidate-left">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={filterIcon} alt="" />
                  Filters
                </h3>
                <Button type="link" block onClick={handleResetData}>
                  Clear all
                </Button>
              </Flex>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default CandidatePage;
