import React, { Children, useState } from 'react';
import MainHeader from '../components/Headers/MainHeader';
import '../styles/DashboardLayout.css'; 
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Headers/Sidebar';

function DashboardLayout({children}) {
  const [menuOpen,setMenuOpen]= useState(false)
  const handleMenu=()=>setMenuOpen(!menuOpen)
  return (
    // <div className="dashboard-layout">
    //   <MainHeader />
    //   <div className="content">
    //     <Sidebar />
    //     <main className="main-content">
    //       {/* <Outlet /> */}
    //       {children}
    //     </main>
    //   </div>
    // </div>

    <div className="dashboard-layout">
      <MainHeader className="main-header" handleMenu={handleMenu}/>
      <div className="content">
        <Sidebar className="sidebar" menuOpen={menuOpen}/>
        <main className="main-content">
          {children}
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;



// import React from 'react';
// import MainHeader from '../components/Headers/MainHeader';
// import '../styles/DashboardLayout.css'; 
// import { Outlet } from 'react-router-dom';
// import Sidebar from '../components/Headers/Sidebar';

// function DashboardLayout() {
//   return (
//     <div className="dashboard-layout">
//       <MainHeader />
//       <div className="content">
//         <Sidebar />
//         <main className="main-content">
//           <Outlet />
//         </main>
//       </div>
//     </div>
//   );
// }

// export default DashboardLayout;
