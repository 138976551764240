import React, { useEffect, useState } from "react";
import {
  createRecruiterIcon,
  editIcon,
  exportIcon,
  filterIcon,
  importIcon,
  mailCheckIcon,
  mailIcon,
  phoneIcon,
  profileUserIcon,
  rightUserIcon,
  tableDeleteIcon,
  tableEditIcon,
  twoUserIcon,
  userRoundXIcon,
} from "../assets";
import { Button, DatePicker, Input, Modal, Select, Table } from "antd";
import "../styles/ClientsPage.css";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import Toast from "../components/Toast/Toast";
import { get, post, put } from "../lib/Api";
import StatusBadge from "../components/table/StatusBadge";
import ConfirmationModal from "../components/modal/ConfirmationModal";
import { ToastContainer } from "react-toastify";
import ImportFileModal from "../components/modal/ImportFileModal ";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ClientsPage = () => {
  const [data, setData] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  const [profileData, setProfileData] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dateFormat = "DD/MM/YYYY";

  const [clientFormData, setClientFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    gstNumber: "",
    spokeName: "",
    address1: "",
    address2: "",
    country: 1,
    state: "",
    city: "",
    pincode: "",
    dateOfStarting: "",
  });

  const [clientCount, setClientCount] = useState({
    totalClients: "",
    activeClients: "",
    inactiveClients: "",
    invitedClients: "",
  });

  const [open, setOpen] = useState(false);
  const showCreateClientModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const [profileOpen, setProfileOpen] = useState(false);
  const showProfileModal = () => {
    setProfileOpen(true);
  };
  const handleProfileOk = () => {
    setProfileOpen(false);
  };
  const handleProfileCancel = () => {
    setProfileOpen(false);
  };

  const openImportModal = () => {
    setIsImportModalOpen(true);
  };

  const handleImportCancel = () => {
    setIsImportModalOpen(false);
    setFile(null);
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
    },
    sortOrder: "",
  });

  const columns = [
    {
      title: "Name & Email",
      sorter: true,
      key: "name",
      render: (result) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <div><img src={profileUserIcon} alt="User Icon"/></div> */}
          <div
            style={{ paddingLeft: "10px", cursor: "pointer" }}
            onClick={() => {
              showProfileModal();
              setProfileData(result);
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#00adf0"; // Change background color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "black"; // Reset background color on hover out
            }}
          >
            <div
              style={{ fontWeight: 500, fontSize: "14px", lineHeight: "17px" }}
            >{`${result.name}`}</div>
            <div
              style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}
            >
              {result.email}
            </div>
          </div>
        </div>
      ),
      width: "25%",
    },
    {
      title: "Client Code",
      dataIndex: "client_code",
      width: "13%",
      align: "center",
      key: "client_code",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      width: "13%",
      align: "center",
      key: "phone_number",
      render: (result) => <>{result === "" || null ? "-" : result}</>,
    },
    {
      title: "Spoke Name",
      dataIndex: "spoke_name",
      width: "13%",
      align: "center",
      key: "spoke_name",
      render: (result) => <>{result === "" || null ? "-" : result}</>,
    },
    {
      title: "Date of Joining",
      sorter: true,
      dataIndex: "start_date",
      width: "15%",
      align: "center",
      key: "start_date",
      render: (result) => <>{result === null ? "-" : result}</>,
    },
    {
      title: "Created At",
      sorter: true,
      dataIndex: "created_at",
      width: "15%",
      align: "center",
      key: "created_at",
      render: (result) => <>{moment(result).format("DD-MM-YYYY")}</>,
    },
    {
      title: "Status",
      // dataIndex: "is_active",
      align: "center",
      key: "is_active",
      render: (result) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          title="Change the Status"
          onClick={() => {
            setIsStatusModalOpen(true);
            setProfileData(result);
          }}
        >
          <StatusBadge
            status={result.is_active === true ? "Active" : "Inactive"}
          />
          <div></div>
        </div>
      ),
      width: "10%",
    },
    {
      title: "",
      render: (result) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            title="Edit Client"
            onClick={() => {
              setProfileData(result);
              setClientFormData({
                name: result?.name,
                email: result?.email,
                phoneNumber: result?.phone_number,
                gstNumber: result?.gst_number,
                spokeName: result?.spoke_name,
                address1: result?.address_1,
                address2: result?.address_2,
                country: result?.country_id,
                state: result?.state_id,
                city: result?.city_id,
                pincode: result?.pin_code,
                dateOfStarting: result?.start_date
                  ? moment(result?.start_date, dateFormat)
                  : "",
              });
              showCreateClientModal();
              setProfileOpen(false);
              setEditModalVisible(true);
            }}
          >
            <img src={tableEditIcon} alt="Edit" />
          </div>
          <div style={{ cursor: "pointer" }} title="Delete Client">
            <img
              src={tableDeleteIcon}
              alt="Delete"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setProfileData({ ...profileData, id: result.id });
              }}
            />
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const getRandomuserParams = (params) => ({
    // results: params.pagination?.pageSize,
    page_no: params.pagination?.current,
    page_size: params.pagination?.pageSize,
    query: debouncedValue,
    status: selectedStatus,
    sort_order: params.sortOrder,
    // ...params,
  });

  const fetchData = async () => {
    setLoading(true);
    const res = await get(
      `client/list`,
      getRandomuserParams(tableParams),
      {},
      true
    );
    if (res?.status === true) {
      setData(res?.client_list.results);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res?.count,
        },
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchClientCount = async () => {
    setLoading(true);
    const res = await get(`client/count`, null, {}, true);
    if (res?.status === true) {
      setClientCount({
        totalClients: res?.data.total,
        activeClients: res?.data.active,
        inactiveClients: res?.data.inactive,
        invitedClients: res?.data.invited,
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchCountries = async () => {
    setLoading(true);
    const res = await get(`master/country-list`, "", {}, true);
    if (res?.status === true) {
      setCountries(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    setLoading(true);
    const res = await get(
      `master/state-list`,
      { country_id: clientFormData.country },
      {},
      true
    );
    if (res?.status === true) {
      setStates(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchCities = async () => {
    setLoading(true);
    const res = await get(
      `master/city-list`,
      { state_id: clientFormData.state },
      {},
      true
    );
    if (res?.status === true) {
      setCities(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (clientFormData.country) {
      fetchStates();
    }
  }, [clientFormData.country]);

  useEffect(() => {
    if (clientFormData.state) {
      fetchCities();
    }
  }, [clientFormData.state]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    fetchData();
    fetchClientCount();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    debouncedValue,
    selectedStatus,
  ]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === "phoneNumber" || id === "pincode") {
      if (/^[0-9]*$/.test(value) || value === "") {
        setClientFormData({
          ...clientFormData,
          [id]: value,
        });
      }
    } else {
      setClientFormData({
        ...clientFormData,
        [id]: value,
      });
    }
  };

  const handleStateChange = (value, option) => {
    setClientFormData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleDateChange = (date, id) => {
    setClientFormData({
      ...clientFormData,
      [id]: date,
    });
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleFileImport = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await post(
        `client/bulk-import`,
        formData,
        { "Content-Type": "multipart/form-data" },
        true
      );

      Toast({
        message: res?.message || "Imported successfully",
        type: "success",
      });
      fetchData();
      fetchClientCount();
      setLoading(false);
      return res.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      Toast({
        message: error.response?.data?.message || "Upload failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const exportClientData = async () => {
    setLoading(true);
    try {
      let res;
      res = await post(`client/bulk-export`, data, {}, true);
      if (res?.status === true) {
        const element = document.createElement("a");
        element.href = baseUrl + res.file_path;
        element.download = "";
        element.click();
        Toast({ message: "Report successfully downloaded", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: "Network Error",
        type: "error",
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters,
      sortOrder: Array.isArray(sorter)
        ? undefined
        : `${sorter.columnKey}: ${sorter.order}`,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSaveClient = async () => {
    setLoading(true);
    try {
      if (!clientFormData.name || !clientFormData.dateOfStarting) {
        Toast({ message: "Please fill all required files", type: "error" });
        setLoading(false);
        return;
      }
      let res;
      if (editModalVisible) {
        // Update client
        const data = {
          ...clientFormData,
          dateOfStarting: clientFormData?.dateOfStarting
            ? clientFormData.dateOfStarting.format(dateFormat)
            : "",
          id: profileData.id,
        };
        res = await put(`client/update`, data, {}, true);
        if (res?.status === true) {
          Toast({ message: "Client updated successfully", type: "success" });
        } else {
          Toast({ message: res?.message, type: "error" });
        }
      } else {
        const data = {
          ...clientFormData,
          dateOfStarting: clientFormData?.dateOfStarting
            ? clientFormData.dateOfStarting.format(dateFormat)
            : "",
        };
        res = await post(`client/create`, data, {}, true);
        if (res?.status === true) {
          Toast({ message: "Client created successfully", type: "success" });
        } else {
          Toast({ message: res?.message, type: "error" });
        }
      }

      if (res?.status === true) {
        setClientFormData({
          name: "",
          email: "",
          phoneNumber: "",
          gstNumber: "",
          spokeName: "",
          address1: "",
          address2: "",
          country: 1,
          state: "",
          city: "",
          pincode: "",
          dateOfStarting: "",
        });
        fetchData();
        fetchClientCount();
        setOpen(false);
        setEditModalVisible(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to ${editModalVisible ? "update" : "create"} client`,
        type: "error",
      });
    }
  };

  const handleDeleteClient = async () => {
    setLoading(true);
    try {
      let res;

      const data = { id: profileData.id };
      res = await put(`client/delete`, data, {}, true);
      if (res?.status === true) {
        Toast({ message: "Client Deleted successfully", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        fetchData();
        fetchClientCount();
        setIsDeleteModalOpen(false);
        setProfileOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to client Delete`,
        type: "error",
      });
    }
  };

  const handleStatusClient = async () => {
    setLoading(true);
    try {
      let res;

      const data = { id: profileData.id };
      res = await put(`client/status`, data, {}, true);
      if (res?.status === true) {
        Toast({
          message: "Client Status successfully changed",
          type: "success",
        });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        fetchData();
        fetchClientCount();
        setIsStatusModalOpen(false);
        setProfileOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to change status for this client`,
        type: "error",
      });
    }
  };

  return (
    <div className="rcontainer">
      <div className="header">
        <div>
          <div className="header-title">Clients</div>
          <div className="header-subtitle">
            Onboard new Clients by assigning the specific job and recruiter
          </div>
        </div>
        <div className="button-group">
          <div>
            <button className="secondary-button" onClick={exportClientData}>
              <img src={exportIcon} alt="exportIcon" />
              Export
            </button>
          </div>
          <div>
            <button
              className="secondary-button"
              title="Import bulk Recruiters"
              onClick={openImportModal}
            >
              <img src={importIcon} alt="importIcon" />
              Import
            </button>
          </div>
          <div>
            <button
              className="jprimary-button"
              onClick={() => {
                showCreateClientModal();
                setEditModalVisible(false);
                setClientFormData({
                  name: "",
                  email: "",
                  phoneNumber: "",
                  gstNumber: "",
                  spokeName: "",
                  address1: "",
                  address2: "",
                  country: 1,
                  state: "",
                  city: "",
                  pincode: "",
                });
              }}
            >
              <img src={createRecruiterIcon} alt="createClientIcon" />
              Create Client
            </button>
          </div>
        </div>
      </div>

      <div className="stats-cards">
        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Total Clients</div>
            <div className="value">{clientCount?.totalClients}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={rightUserIcon} alt="rightUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Active Clients</div>
            <div className="value">{clientCount?.activeClients}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={userRoundXIcon} alt="userRoundXIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Inactive Clients</div>
            <div className="value">{clientCount?.inactiveClients}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={mailCheckIcon} alt="mailCheckIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Invited</div>
            <div className="value">{clientCount?.invitedClients}</div>
          </div>
        </div>
      </div>

      <div className="filter-section">
        <div className="filter-bar">
          <div className="filter-group">
            <div>
              <Input
                placeholder="Search by Name, Email, Phone Number or Client code"
                className="search-input"
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "20px",
                    }}
                  />
                }
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div>
              <Select
                defaultValue="all"
                className="filter-select"
                onChange={handleStatusChange}
                options={[
                  { value: "all", label: "All" },
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
              />
            </div>
          </div>
          {/* <div>
            <button className="filter-button">
              <img src={filterIcon} alt="Filter Icon" /> <span>Filter</span>
            </button>
          </div> */}
        </div>
        <div className="table-container">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <Modal
        open={open}
        width={760}
        title={editModalVisible ? "Update Client" : "Create Client"}
        maskClosable={false}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className="create-modal-cancel-button"
              onClick={() => {
                setOpen(false);
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              className="create-modal-confirm-button"
              onClick={handleSaveClient}
              disabled={loading}
            >
              {" "}
              {editModalVisible
                ? loading
                  ? "Updating..."
                  : "Update"
                : loading
                ? "Creating..."
                : "Create"}
            </Button>
          </>
        )}
      >
        <hr />
        <div className="client-create-raw">
          <div className="client-create-input-div">
            {" "}
            <label htmlFor="name">
              {" "}
              Name <span className="text-danger">*</span>
            </label>
            <Input
              id="name"
              placeholder="Enter client's name"
              className="client-create-input"
              value={clientFormData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="client-create-input-div">
            <label htmlFor="email">Email</label>
            <Input
              id="email"
              placeholder="Enter client's email"
              className="client-create-input"
              value={clientFormData.email}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="client-create-raw" style={{ paddingTop: "15px" }}>
          <div className="client-create-input-div">
            {" "}
            <label htmlFor="phoneNumber"> Phone Number </label>
            <Input
              id="phoneNumber"
              placeholder="Enter Client's Phone Number"
              maxLength="10"
              className="client-create-input"
              value={clientFormData.phoneNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="client-create-input-div">
            <label htmlFor="gstNumber">GST Number</label>
            <Input
              id="gstNumber"
              placeholder="Enter Client's GST Number"
              maxLength="15"
              className="client-create-input"
              value={clientFormData.gstNumber}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="client-create-raw" style={{ paddingTop: "15px" }}>
          <div className="client-create-input-div">
            {" "}
            <label htmlFor="spokeName">Spoke Name</label>
            <Input
              id="spokeName"
              placeholder="Enter Spoke Name"
              className="client-create-input"
              value={clientFormData.spokeName}
              onChange={handleInputChange}
            />
          </div>
          <div className="client-create-input-div">
            {" "}
            <label htmlFor="address1">Address 1</label>
            <Input
              id="address1"
              placeholder="Enter client's Address1"
              className="client-create-input"
              value={clientFormData.address1}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="client-create-raw" style={{ paddingTop: "15px" }}>
          <div className="client-create-input-div">
            {" "}
            <label htmlFor="address2">Address 2</label>
            <Input
              id="address2"
              placeholder="Enter client's Address2"
              className="client-create-input"
              value={clientFormData.address2}
              onChange={handleInputChange}
            />
          </div>
          <div className="client-create-input-div">
            {" "}
            <label htmlFor="country ">Country </label>
            <Select
              id="country"
              placeholder="Select a Country"
              className="client-create-select-input"
              onChange={handleStateChange}
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
                id: "country",
              }))}
              value={clientFormData.country || undefined}
            />
          </div>
        </div>

        <div className="client-create-raw" style={{ paddingTop: "15px" }}>
          <div className="client-create-input-div">
            {" "}
            <label htmlFor="state">State</label>
            <Select
              id="state"
              placeholder="Select a state"
              className="client-create-select-input"
              onChange={handleStateChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={states.map((state) => ({
                value: state.id,
                label: state.name,
                id: "state",
              }))}
              value={clientFormData.state || undefined}
            />
          </div>
          <div className="client-create-input-div">
            <label htmlFor="city">City</label>
            <Select
              id="city"
              placeholder="Select a City"
              className="client-create-select-input"
              onChange={handleStateChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={cities.map((city) => ({
                value: city.id,
                label: city.name,
                id: "city",
              }))}
              value={clientFormData.city || undefined}
            />
          </div>
        </div>

        <div className="client-create-raw" style={{ paddingTop: "15px" }}>
          <div className="client-create-input-div">
            <label htmlFor="pincode">Pin Code</label>
            <Input
              id="pincode"
              placeholder="Enter Pincode"
              maxLength="6"
              className="client-create-input"
              value={clientFormData.pincode}
              onChange={handleInputChange}
            />
          </div>
          <div className="client-create-input-div">
            {" "}
            <label htmlFor="dateOfStarting">
              Date of starting <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="dateOfStarting"
              disabledDate={(current) => {
                return current && current > moment().endOf("day");
              }}
              className="client-create-date-input"
              format={dateFormat}
              disabled={editModalVisible}
              value={clientFormData.dateOfStarting}
              onChange={(date) => handleDateChange(date, "dateOfStarting")}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={profileOpen}
        width={486}
        height={1000}
        className="ant-modal-content-modify"
        style={{
          position: "fixed",
          top: "-7px",
          right: "-7px",
          backgroundColor: "#FFFFFF",
          padding: 0,
        }}
        title=" &nbsp; &nbsp;  View Client Details"
        onOk={handleProfileOk}
        onCancel={handleProfileCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <div className="profile-footer">
            {/* <Button
              className="client-reset-password-button"
              onClick={() => {
                alert("Okay");
              }}
            >
              {" "}
              Reset password{" "}
            </Button> */}
            <Button
              className="client-delete-button"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setProfileData(profileData);
                setProfileOpen(false);
              }}
            >
              {" "}
              Delete Client{" "}
            </Button>
          </div>
        )}
      >
        <div className="profile-main-details">
          <div style={{ flex: 1 }}>
            <img src={profileUserIcon} alt="User" width="80px" height="80px" />
          </div>
          <div style={{ flex: 3 }}>
            <div style={{ display: "flex", gap: "6px" }}>
              <div className="profile-name-text"> {profileData?.name} </div>

              {profileData?.is_active === true ? (
                <div
                  className="active-lable"
                  onClick={() => {
                    setIsStatusModalOpen(true);
                  }}
                >
                  Active
                </div>
              ) : (
                <div
                  className="inactive-lable"
                  onClick={() => {
                    setIsStatusModalOpen(true);
                  }}
                >
                  Inactive
                </div>
              )}
            </div>
            {profileData?.email && (
              <div className="profile-content-div">
                {" "}
                <div className="profile-icon">
                  <img src={mailIcon} alt="Mail Icon" />
                </div>{" "}
                <div className="profile-content-">{profileData?.email}</div>
              </div>
            )}
            {profileData?.phone_number && (
              <div className="profile-content-div">
                {" "}
                <div className="profile-icon">
                  <img src={phoneIcon} alt="Phone Icon" />
                </div>{" "}
                <div className="profile-content-">
                  {profileData?.phone_number}
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ height: "575px" }}>
          <div className="profile-details-div">
            Client Details{" "}
            <button
              className="client-edit-button"
              onClick={() => {
                setClientFormData({
                  name: profileData?.name,
                  email: profileData?.email,
                  phoneNumber: profileData?.phone_number,
                  gstNumber: profileData?.gst_number,
                  spokeName: profileData?.spoke_name,
                  address1: profileData?.address_1,
                  address2: profileData?.address_2,
                  country: profileData?.country_id,
                  state: profileData?.state_id,
                  city: profileData?.city_id,
                  pincode: profileData?.pin_code,
                  dateOfStarting: profileData?.start_date
                    ? moment(profileData?.start_date, dateFormat)
                    : "",
                });
                showCreateClientModal();
                setProfileOpen(false);
                setEditModalVisible(true);
              }}
            >
              <img src={editIcon} alt="Edit Icon" /> Edit
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 20px",
              gap: "4px",
            }}
          >
            <div>
              <div className="details-role">Name</div>
              <div className="details-role">Email</div>
              <div className="details-role">Phone Number</div>
              <div className="details-role">GST Number</div>
              <div className="details-role">Spoke Name</div>
              <div className="details-role">Address 1</div>
              <div className="details-role">Address 2</div>
              <div className="details-role">Country</div>
              <div className="details-role">State</div>
              <div className="details-role">City</div>
              <div className="details-role">Pin Code</div>
              <div className="details-role">Date of Starting</div>
            </div>
            <div>
              <div className="details-role-name">
                : &nbsp; {profileData?.name || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.email || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.phone_number || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.gst_number || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.spoke_name || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.address_1 || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.address_2 || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.country || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.state || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.city || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.pin_code || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.start_date || "N/A"}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </Modal>

      <ImportFileModal
        isVisible={isImportModalOpen}
        onClose={handleImportCancel}
        onImport={handleFileImport}
        downloadSampleSheetUrl="client/sample-sheet"
        title="Import Excel File"
      />

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onConfirm={handleDeleteClient}
        onCancel={() => setIsDeleteModalOpen(false)}
        loading={loading}
        title="Confirm Delete"
        message="Are you sure you want to delete this client?"
      />

      <ConfirmationModal
        isOpen={isStatusModalOpen}
        onConfirm={handleStatusClient}
        onCancel={() => setIsStatusModalOpen(false)}
        loading={loading}
        title="Confirm Status Change"
        // message="Are you sure you want to change status this client?"
        message={`Are you sure you want to change ${
          profileData?.name
        }'s status to ${
          profileData.is_active == true ? "inactive" : "active"
        }?`}
      />

      <ToastContainer />
    </div>
  );
};

export default ClientsPage;
