import React, { useEffect, useRef, useState } from "react";
import "../styles/CandidateDetails.css";
import { Avatar, Button, Flex, Input, Modal, Space, Tabs, Tag } from "antd";
import {
  CloseOutlined,
  UserOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  assetsdocumentsIcon,
  bagIcon,
  blackListIcon,
  bookIcon,
  candidatepreferenceIcon,
  ClockIcon,
  commentIcon,
  copyLinkIcon,
  departmentsIcon,
  Downolad1Icon,
  editIcon,
  fileIcon,
  industriesIcon,
  languagesIcon,
  mail1Icon,
  mapIcon,
  mayalsoknowIcon,
  personaldetailsIcon,
  phone1Icon,
  preferredlocationsIcon,
  sendIcon,
  skillsIcon,
  trashIcon,
  UnlockIcon,
  walletIcon,
} from "../assets";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import Toast from "../components/Toast/Toast";
import { post, put } from "../lib/Api";
import moment from "moment";
import ConfirmationModal from "../components/modal/ConfirmationModal";
const baseUrl = process.env.REACT_APP_BACKEND_URL;
const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

const CandidateDetails = ({
  visibleDetails,
  candidate,
  onClose,
  onBlacklistSuccess,
}) => {
  const navigate = useNavigate();
  const userName = localStorage.getItem("__user_name__");
  const userType = localStorage.getItem("__user_type__");
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingCommentText, setEditingCommentText] = useState("");
  const editingRef = useRef(null);
  const [deleteCommentId, setDeleteCommentId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [comments, setComments] = useState(candidate?.candidate_notes || []);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [isCvDownloadModalOpen, setIsCvDownloadModalOpen] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editingRef.current && !editingRef.current.contains(event.target)) {
        setEditingCommentId(null);
        setEditingCommentText("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setComments(candidate?.candidate_notes);
  }, [candidate]);

  const handlePhoneNumberClick = () => {
    setShowPhoneNumber(!showPhoneNumber);
  };

  const handleDownloadCV = async () => {
    try {
      const data = {
        candidate_profile_id: candidate?.id,
      };
      const response = await post("candidate/cv-download", data, "", true);
      if (response?.status === true) {
        const url = baseUrl + response?.data?.cv_path;
        window.open(url, "_blank");
        setIsCvDownloadModalOpen(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to Download CV", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCommentModal = () => {
    setShowCommentModal(false);
    setCommentText("");
  };

  const handleNewCommentChange = (e) => {
    const { value } = e.target;
    setNewComment(value);
  };

  const handlePostNewComment = async () => {
    setLoading(true);
    const data = {
      candidate_profile_id: candidate?.id,
      note_description: newComment,
    };
    try {
      const response = await post("candidate/notes-create", data, "", true);
      if (response?.status === true) {
        Toast({ message: response?.message, type: "success" });
        setComments([
          ...comments,
          {
            ...data,
            id: response?.data?.id,
            created_by: response?.data?.created_by,
            created_at: response?.data?.created_at,
          },
        ]);
        setNewComment("");
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditComment = (commentId, commentText) => {
    setEditingCommentId(commentId);
    setEditingCommentText(commentText);
  };

  const handleUpdateComment = async () => {
    setLoading(true);
    const data = {
      id: editingCommentId,
      note_description: editingCommentText,
    };
    try {
      const response = await put(`candidate/notes-update`, data, "", true);
      if (response?.status === true) {
        Toast({ message: response?.message, type: "success" });
        setComments(
          comments.map((comment) =>
            comment.id === editingCommentId
              ? { ...comment, note_description: editingCommentText }
              : comment
          )
        );
        setEditingCommentId(null);
        setEditingCommentText("");
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to update comment", error);
      setLoading(false);
    }
  };

  const handleDeleteComment = async () => {
    setLoading(true);
    const data = {
      id: deleteCommentId,
    };
    try {
      const response = await put(`candidate/notes-delete`, data, "", true);
      if (response?.status === true) {
        Toast({ message: response?.message, type: "success" });
        setIsDeleteModalOpen(false);
        setEditingCommentId(null);
        setComments(
          comments.filter((comment) => comment.id !== deleteCommentId)
        );
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to delete comment", error);
      setLoading(false);
    }
  };

  const handleBlacklist = async () => {
    setLoading(true);
    const data = {
      candidate_profile_id: candidate?.id,
      reason: commentText,
    };
    try {
      const response = await post("candidate/blacklist-create", data, "", true);
      if (response?.status === true) {
        Toast({ message: response?.message, type: "success" });
        setShowCommentModal(false);
        setCommentText("");
        setLoading(false);
        onBlacklistSuccess(true);
        onClose();
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileLink = async () => {
    setLoading(true);
    const data = {
      candidate_profile_id: candidate?.id,
    };
    try {
      const response = await post("candidate/self-token", data, "", true);
      if (response?.status === true) {
        const textToCopy = `${frontendUrl}candidate-self-update/${candidate?.id}/${response?.data.token}`;
        // navigator.clipboard
        //   .writeText(textToCopy)
        //   .then(() => {
        //     Toast({ message: "Link copied to clipboard", type: "success" });
        //   })
        //   .catch((err) => {
        //     console.error("Failed to copy link: ", err);
        //   });

        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          Toast({ message: "Link copied to clipboard", type: "success" });
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
          Toast({ message: "Failed to copy link", type: "error" });
        }
        document.body.removeChild(textArea);

        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    } else {
      return nameParts.map((part) => part[0].toUpperCase()).join("");
    }
  };

  return visibleDetails ? (
    <div className="candidate-details">
      <div className="candidate-details-overlay" onClick={onClose} />
      <div className="candidate-details-modal">
        <div className="modal-header">
          <Flex justify="space-between" align="center">
            <div className="candidate-user-info">
              <Avatar icon={<UserOutlined />} />
              <h2>
                {candidate?.name}
                <button
                  className="candidate-edit-button"
                  onClick={() => {
                    navigate(`/update-candidate/${candidate?.id}`);
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  <img src={editIcon} alt="Edit Icon" /> Edit
                </button>

                <button
                  className="candidate-copylink-button"
                  disabled={loading}
                  onClick={handleProfileLink}
                  style={{ marginLeft: "10px" }}
                >
                  <img src={copyLinkIcon} alt="Edit Icon" /> Profile Link
                </button>

                <button
                  className="candidate-blacklist-button"
                  onClick={() => {
                    setShowCommentModal(true);
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  <img src={blackListIcon} alt="Edit Icon" /> Blacklist
                </button>
              </h2>
            </div>
            <Button
              type="text"
              ghost
              icon={<CloseOutlined />}
              onClick={onClose}
            />
          </Flex>
          <div className="candidate-user-bio">
            <Flex align="center" wrap>
              <div className="user-bio-list">
                <img src={bagIcon} alt="" />
                <span>{candidate?.experience_status} </span>
              </div>
              <div className="user-bio-list">
                <img src={mapIcon} alt="" />
                <span>
                  {candidate?.city}, {candidate?.state}
                </span>
              </div>
              <div className="user-bio-list">
                <img src={walletIcon} alt="" />
                <span> ₹ {candidate?.annual_salary}</span>
              </div>
            </Flex>
          </div>
        </div>
        <div className="modal-body">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: "Full Profile",
                key: "1",
                children: (
                  <div>
                    <ul>
                      {/* <li>
                        <div className="user-bio-list">
                          <img src={bagIcon} alt="" />
                          <span>Work Experience</span>
                        </div>
                        <p className="dots">
                          Marketing Agent at VST Family | 2022-present
                        </p>
                      </li> */}
                      {/* <li>
                        <div className="user-bio-list">
                          <img src={industriesIcon} alt="" />
                          <span>Industries</span>
                        </div>
                        <Space direction="horizontal" className="tags-wrapper">
                          <Tag>Beverage/Brewery/Distillery</Tag>
                        </Space>
                      </li> */}
                      <li>
                        <div className="user-bio-list">
                          <img src={departmentsIcon} alt="" />
                          <span>Departments</span>
                        </div>
                        <Space direction="horizontal" className="tags-wrapper">
                          <Tag>{candidate?.designation}</Tag>
                          {/* <Tag>Sales & BD</Tag> */}
                        </Space>
                      </li>
                      <li>
                        <div className="user-bio-list">
                          <img src={bookIcon} alt="" />
                          <span>Education</span>
                        </div>
                        <p className="dots">{candidate?.qualification}</p>
                      </li>
                      <li>
                        <div className="user-bio-list">
                          <img src={skillsIcon} alt="" />
                          <span>Skills</span>
                        </div>
                        <Space direction="horizontal" className="tags-wrapper">
                          {candidate?.skills_name.map((language, index) => {
                            return <Tag key={index}>{language}</Tag>;
                          })}
                        </Space>
                      </li>
                      {/* <li>
                        <div className="user-bio-list">
                          <img src={mayalsoknowIcon} alt="" />
                          <span>May also know</span>
                        </div>
                        <Space direction="horizontal" className="tags-wrapper">
                          <Tag>Customer relationship</Tag>
                          <Tag>Brainstorming</Tag>
                          <Tag>Answering questions</Tag>
                          <Tag>Anger management</Tag>
                          <Tag>Employee Handling</Tag>
                        </Space>
                      </li> */}
                      <li>
                        <div className="user-bio-list">
                          <img src={preferredlocationsIcon} alt="" />
                          <span>Preferred locations</span>
                        </div>
                        <Space direction="horizontal" className="tags-wrapper">
                          {candidate?.preffered_location_name?.map(
                            (location, index) => {
                              return <Tag key={index}>{location}</Tag>;
                            }
                          )}
                        </Space>
                      </li>
                      {/* <li>
                        <div className="user-bio-list">
                          <img src={candidatepreferenceIcon} alt="" />
                          <span>Candidate preference</span>
                        </div>
                        <Space direction="horizontal" className="tags-wrapper">
                          <Tag>Field Job/ Work from Office</Tag>
                          <Tag>Full Time</Tag>
                          <Tag>Day Shift/Night Shift</Tag>
                        </Space>
                      </li> */}
                      <li>
                        <div className="user-bio-list">
                          <img src={languagesIcon} alt="" />
                          <span>Languages</span>
                        </div>
                        <Space direction="horizontal" className="tags-wrapper">
                          {candidate?.language_name?.map((language, index) => {
                            return <Tag key={index}>{language}</Tag>;
                          })}
                        </Space>
                      </li>
                      {/* <li>
                        <div className="user-bio-list">
                          <img src={assetsdocumentsIcon} alt="" />
                          <span>Assets & Documents</span>
                        </div>
                        <Space direction="horizontal" className="tags-wrapper">
                          <Tag>Wifi</Tag>
                          <Tag>Bike</Tag>
                        </Space>
                      </li> */}
                      <li>
                        <div className="user-bio-list">
                          <img src={personaldetailsIcon} alt="" />
                          <span>Personal details</span>
                        </div>
                        <ul className="personal-details">
                          <li>
                            <h6>Gender</h6>
                            <span>{candidate?.gender}</span>
                          </li>
                          <li>
                            <h6>Age</h6>
                            <span>{candidate?.dob}</span>
                          </li>
                          <li>
                            <h6>Phone number</h6>
                            <span>{candidate?.phone_number}</span>
                          </li>
                        </ul>
                      </li>
                      <li className="resume-box">
                        <Flex justify="space-between" align="center">
                          <div className="user-bio-list">
                            <img src={fileIcon} alt="" />
                            <span>CV/Resume</span>
                          </div>
                          {candidate?.cv && (
                            <Button
                              type="link"
                              block
                              onClick={() => {
                                setIsCvDownloadModalOpen(true);
                              }}
                            >
                              <img src={Downolad1Icon} alt="" />
                              Download CV
                            </Button>
                          )}
                        </Flex>
                        {candidate?.cv ? (
                          <embed
                            // src="http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0"
                            src={baseUrl + candidate?.cv}
                            type="application/pdf"
                            frameBorder="0"
                            scrolling="auto"
                            height="1000"
                            width="100%"
                          ></embed>
                        ) : (
                          "Not available"
                        )}
                      </li>
                      <li className="comment-box">
                        <div className="user-bio-list">
                          <img src={commentIcon} alt="" />
                          <span>Comments</span>
                        </div>
                        <div className="comment-wrapper">
                          <div className="comment-input">
                            <Flex gap="10px" style={{ marginBottom: "10px" }}>
                              <Avatar>{getInitials(userName)}</Avatar>
                              <TextArea
                                rows={4}
                                placeholder="Write a comment"
                                onChange={handleNewCommentChange}
                                value={newComment}
                              />
                            </Flex>
                            <Button
                              type="primary"
                              block
                              icon={<img src={sendIcon} alt="icon" />}
                              disabled={loading || !newComment}
                              onClick={handlePostNewComment}
                            >
                              Post
                            </Button>
                          </div>
                          <div className="comment-items">
                            {comments?.map((note) => {
                              return (
                                <div
                                  className="comment-item"
                                  key={note.id}
                                  ref={
                                    editingCommentId === note.id
                                      ? editingRef
                                      : null
                                  }
                                >
                                  {editingCommentId === note.id ? (
                                    <div className="comment-input">
                                      <Flex
                                        gap="10px"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <Avatar>
                                          {getInitials(note?.created_by)}
                                        </Avatar>
                                        <TextArea
                                          rows={4}
                                          value={editingCommentText}
                                          onChange={(e) =>
                                            setEditingCommentText(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Flex>
                                      <Flex
                                        align="center"
                                        gap="10px"
                                        justify="flex-end"
                                      >
                                        <Button
                                          type="primary"
                                          block
                                          onClick={handleUpdateComment}
                                          loading={loading}
                                          disabled={
                                            loading || !editingCommentText
                                          }
                                        >
                                          Update
                                        </Button>
                                      </Flex>
                                    </div>
                                  ) : (
                                    <>
                                      <Flex gap="16px">
                                        <Avatar>
                                          {getInitials(note?.created_by)}
                                        </Avatar>
                                        <div className="comment-info">
                                          <p>
                                            {note?.created_by}{" "}
                                            <span>
                                              {moment(note?.created_at).format(
                                                "DD MMMM, YYYY"
                                              )}
                                            </span>
                                          </p>
                                          <span>{note?.note_description}</span>
                                        </div>
                                      </Flex>
                                      {userType === "admin" ||
                                      userName === note?.created_by ? (
                                        <Flex
                                          justify="end"
                                          gap="17px"
                                          className="edit-delete-btn"
                                        >
                                          <Button
                                            icon={
                                              <img src={trashIcon} alt="icon" />
                                            }
                                            iconPosition="start"
                                            type="text"
                                            onClick={() => {
                                              setDeleteCommentId(note.id);
                                              setIsDeleteModalOpen(true);
                                            }}
                                          >
                                            Delete
                                          </Button>
                                          <Button
                                            icon={
                                              <img src={editIcon} alt="icon" />
                                            }
                                            iconPosition="start"
                                            type="text"
                                            className="edit-btn"
                                            onClick={() =>
                                              handleEditComment(
                                                note.id,
                                                note.note_description
                                              )
                                            }
                                          >
                                            Edit
                                          </Button>
                                        </Flex>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </div>
                              );
                            })}

                            {/* <div className="comment-item">
                              <div className="comment-input">
                                <Flex
                                  gap="10px"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Avatar>RJ</Avatar>
                                  <TextArea
                                    rows={4}
                                    value="I’m updating this comment. "
                                  />
                                </Flex>
                                <Flex
                                  align="center"
                                  gap="10px"
                                  justify="flex-end"
                                >
                                 
                                  <Button type="primary" block>
                                    Update
                                  </Button>
                                </Flex>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <Flex
                      gap="small"
                      wrap
                      justify="flex-end"
                      className="candidate-button-group"
                      style={{ paddingBottom: "25px" }}
                    >
                      <Button
                        type="default"
                        size="large"
                        icon={<img src={phone1Icon} alt="icon" />}
                        onClick={handlePhoneNumberClick}
                      >
                        {showPhoneNumber
                          ? candidate.phone_number
                          : "View Phone Number"}
                      </Button>
                      {/* <Button
                        type="default"
                        size="large"
                        icon={<img src={mail1Icon} alt="icon" />}
                      >
                        View Email
                      </Button> */}
                      <Button
                        type="default"
                        size="large"
                        icon={<img src={Downolad1Icon} alt="icon" />}
                        onClick={() => {
                          setIsCvDownloadModalOpen(true);
                        }}
                      >
                        Download CV
                      </Button>
                    </Flex>
                    <div className="modal-footer">
                      <Flex
                        align="center"
                        justify="space-between"
                        wrap
                        className="modal-unlocks"
                      >
                        {/* <Flex align="center" gap="5px">
                          <img src={UnlockIcon} alt="" />
                          <p>50 unlocks</p>
                        </Flex> */}
                        {/* <Flex align="center" gap="5px">
                          <img src={ClockIcon} alt="" />
                          <p>Active on 18 Jun’24</p>
                        </Flex> */}
                      </Flex>
                      {/* <Flex
                        gap="small"
                        wrap
                        justify="space-between"
                        className="candidate-button-group"
                      >
                        <Button
                          type="text"
                          ghost
                          icon={<LeftOutlined />}
                          className="previous"
                        >
                          Previous Candidate
                        </Button>
                        <Button
                          type="text"
                          className="next"
                          ghost
                          icon={<RightOutlined />}
                        >
                          Next Candidate
                        </Button>
                      </Flex> */}
                    </div>
                  </div>
                ),
              },
              {
                label: "CV/Resume",
                key: "2",
                children: (
                  <div>
                    <h2>CV/Resume</h2>
                  </div>
                ),
              },
              {
                label: "Comments",
                key: "3",
                children: (
                  <div>
                    <h2>Comments</h2>
                  </div>
                ),
              },
            ]}
          />
        </div>
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteComment}
          onCancel={() => setIsDeleteModalOpen(false)}
          loading={loading}
          title="Confirm Delete"
          message="Are you sure you want to delete this comment?"
        />
      </div>

      <ConfirmationModal
        isOpen={isCvDownloadModalOpen}
        onConfirm={() => {
          handleDownloadCV();
        }}
        onCancel={() => setIsCvDownloadModalOpen(false)}
        loading={loading}
        title="Confirm Download"
        message="Are you sure you want to download this candidate's CV?"
      />

      <Modal
        title={`Comment regarding the blacklisting of ${candidate.name}`}
        centered
        open={showCommentModal}
        onCancel={handleCloseCommentModal}
        footer={[
          <Button
            key="cancel"
            className="create-modal-cancel-button"
            onClick={() => {
              setShowCommentModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            className="create-modal-confirm-button"
            onClick={handleBlacklist}
            loading={loading}
            disabled={loading || !commentText}
          >
            Confirm
          </Button>,
        ]}
      >
        <Input.TextArea
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder="Write your comment here..."
          autoSize={{ minRows: 3, maxRows: 8 }}
        />
      </Modal>

      {/* <ToastContainer /> */}
    </div>
  ) : (
    ""
  );
};

export default CandidateDetails;
