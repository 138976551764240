import React from "react";

const Dashboard = () => {
  return (
    <div
      style={{ backgroundColor: "#F2F2F2", height: "100%", padding: "30px" }}
    >
    Dashboard
    </div>
  );
};

export default Dashboard;
