import React, { useEffect, useState } from "react";
import { tableDeleteIcon, twoUserIcon } from "../assets";
import { Input, Table } from "antd";
import "../styles/RecruitersPage.css";
import { get, put } from "../lib/Api";
import { ToastContainer } from "react-toastify";
import Toast from "../components/Toast/Toast";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import ConfirmationModal from "../components/modal/ConfirmationModal";

const BlacklistCandidates = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  const [profileData, setProfileData] = useState({});
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const [blacklistedCount, setBlacklistedCount] = useState(0);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
    },
  });

  const columns = [
    {
      title: "Name",
      sorter: true,
      key: "name",
      render: (result) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ fontWeight: 500, fontSize: "14px", lineHeight: "17px" }}
          >{`${result.name}`}</div>
        </div>
      ),
      width: "25%",
    },
    {
      title: "Candidate Code",
      dataIndex: "candidate_code",
      width: "13%",
      align: "center",
      key: "candidate_code",
    },
    {
      title: "Phone No.",
      dataIndex: "phone_number",
      width: "15%",
      align: "center",
      key: "phone_number",
    },
    {
      title: "Blacklisted by",
      dataIndex: "blacklist_by",
      width: "15%",
      align: "center",
      key: "blacklist_by",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      width: "15%",
      align: "center",
      key: "created_at",
      sorter: true,
      render: (result) => <>{moment(result).format("DD-MM-YYYY")}</>,
    },
    {
      title: "Reason",
      dataIndex: "blacklist_reason",
      width: "15%",
      align: "center",
      key: "blacklist_reason",
    },
    {
      title: "",
      render: (result) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div style={{ cursor: "pointer" }} title="Delete Recruiter">
            <img
              src={tableDeleteIcon}
              alt="Delete"
              onClick={() => {
                setIsRemoveModalOpen(true);
                setProfileData({ ...profileData, id: result.id });
              }}
            />
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const getRandomuserParams = (params) => ({
    page_no: params.pagination?.current,
    page_size: params.pagination?.pageSize,
    query: debouncedValue,
    sort_order: params.sortOrder,
  });

  const fetchData = async () => {
    setLoading(true);
    const res = await get(
      `candidate/blacklist`,
      getRandomuserParams(tableParams),
      {},
      true
    );
    if (res?.status === true) {
      setData(res?.candidate_blacklist.results);
      setBlacklistedCount(res?.total_blacklists);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.count,
        },
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    debouncedValue,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters,
      sortOrder: Array.isArray(sorter)
        ? undefined
        : `${sorter.columnKey}: ${sorter.order}`,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleRemoveCandidate = async () => {
    setLoading(true);
    try {
      let res;

      const data = { candidate_profile_id: profileData.id };
      res = await put(`candidate/blacklist-remove`, data, {}, true);
      if (res?.status === true) {
        Toast({ message: "Candidate removed successfully", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        fetchData();
        setIsRemoveModalOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to remove candidate`,
        type: "error",
      });
    }
  };

  return (
    <div className="rcontainer">
      <div className="header">
        <div>
          <div className="header-title">Blacklist Candidates</div>
          <div className="header-subtitle">
            Blacklist candidates based on their specific roles, work, and
            permissions.
          </div>
        </div>
      </div>

      <div className="stats-cards">
        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Blacklist Candidates</div>
            <div className="value">{blacklistedCount}</div>
          </div>
        </div>
      </div>

      <div className="filter-section">
        <div className="filter-bar">
          <div className="filter-group">
            <div>
              <Input
                placeholder="Search by Name, Email, PhoneNo or Recruiter Code"
                className="search-input"
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "20px",
                    }}
                  />
                }
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="table-container">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>

      <ConfirmationModal
        isOpen={isRemoveModalOpen}
        onConfirm={handleRemoveCandidate}
        onCancel={() => setIsRemoveModalOpen(false)}
        loading={loading}
        title="Confirm Remove from the Blaklist"
        message="Are you sure you want to remove this candidate from the blacklist?"
      />

      <ToastContainer />
    </div>
  );
};

export default BlacklistCandidates;
