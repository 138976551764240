import React from "react";

const CreditUsagePage = () => {
  return (
    <div
      style={{ backgroundColor: "#F2F2F2", height: "100%", padding: "30px" }}
    >
    Credit Usage Page
    </div>
  );
};

export default CreditUsagePage;
