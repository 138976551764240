import React, { useEffect, useState } from "react";
import { importIcon } from "../assets";
import "../styles/CreateCandidate.css";
import { Input, Row, Col, Button, DatePicker, Select, Upload } from "antd";
import moment from "moment";
import { get, post, put } from "../lib/Api";
import Toast from "../components/Toast/Toast";
import { UploadOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImportFileModal from "../components/modal/ImportFileModal ";
import ImportZipModal from "../components/modal/ImportZipModal";
import InvalidPage from "../components/InvalidPage/InvalidPage";
import SuccessPage from "../components/SuccessPage/SuccessPage";

const CandidateForm = () => {
  const location = useLocation();
  const { id, token } = useParams();
  const navigate = useNavigate();
  const dateFormat = "DD/MM/YYYY";
  const [loading, setLoading] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isImportZipModalOpen, setIsImportZipModalOpen] = useState(false);
  const [qualificationDropData, setQualificationDropData] = useState([]);
  const [designationDropData, setDesignationDropData] = useState([]);
  const [experienceDropData, setExperienceDropData] = useState([]);
  const [sourceDroData, setSourceDropData] = useState([]);
  const [countriesDropData, setCountriesDropData] = useState([]);
  const [statesDropData, setStatesDropData] = useState([]);
  const [citiesDropData, setCitiesDropData] = useState([]);
  const [preferredCitiesDropData, setPreferredCitiesDropData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [skills, setSkills] = useState([]);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isSelfSuccess, setIsSelfSuccess] = useState(false);

  const isCreateMode = location.pathname === "/create-candidate";
  const isUpdateMode = location.pathname.startsWith("/update-candidate");
  const isSelfUpdateMode = location.pathname.startsWith(
    "/candidate-self-update"
  );

  const mode = isCreateMode
    ? "create"
    : isUpdateMode
    ? "update"
    : isSelfUpdateMode
    ? "selfUpdate"
    : null;

  const [genderData, setGenderData] = useState([
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ]);

  const [candidateFormData, setCandidateFormData] = useState({
    name: "",
    phoneNumber: "",
    aadharNumber: "",
    dateOfBirth: "",
    gender: "",
    language: "",
    skills: "",
    currentCompany: "",
    preferredLocation: [],
    annualSalary: "",
    qualification: "",
    designation: "",
    experience: "",
    source: "",
    pinCode: "",
    city: "",
    state: "",
    country: 1,
    cv: null,
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (
      id === "phoneNumber" ||
      id === "aadharNumber" ||
      id === "annualSalary" ||
      id === "pinCode"
    ) {
      if (/^[0-9]*$/.test(value) || value === "") {
        setCandidateFormData({
          ...candidateFormData,
          [id]: value,
        });
      }
    } else {
      setCandidateFormData({
        ...candidateFormData,
        [id]: value,
      });
    }
  };

  const handleDateChange = (date, id) => {
    setCandidateFormData({
      ...candidateFormData,
      [id]: date,
    });
  };

  const handleSelectChange = (value, option) => {
    setCandidateFormData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleFileChange = ({ file }) => {
    if (file.type === "application/pdf") {
      setCandidateFormData({
        ...candidateFormData,
        cv: file,
      });
    } else {
      Toast({ message: "Please upload a PDF file", type: "error" });
    }
  };

  const fetchQualificationDropData = async () => {
    setLoading(true);
    const res = await get(`master/qualification-list`, "", {}, true);
    if (res?.status === true) {
      setQualificationDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchDesignationDropData = async () => {
    setLoading(true);
    const res = await get(`master/designation-list`, "", {}, true);
    if (res?.status === true) {
      setDesignationDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchExperienceDropData = async () => {
    setLoading(true);
    const res = await get(`master/experience-list`, "", {}, true);
    if (res?.status === true) {
      setExperienceDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchSourceDropData = async () => {
    setLoading(true);
    const res = await get(`master/source-list`, "", {}, true);
    if (res?.status === true) {
      setSourceDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchCountries = async () => {
    setLoading(true);
    const res = await get(`master/country-list`, "", {}, true);
    if (res?.status === true) {
      setCountriesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    setLoading(true);
    const res = await get(
      `master/state-list`,
      { country_id: candidateFormData.country },
      {},
      true
    );
    if (res?.status === true) {
      setStatesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchCities = async () => {
    setLoading(true);
    const res = await get(
      `master/city-list`,
      { state_id: candidateFormData.state },
      {},
      true
    );
    if (res?.status === true) {
      setCitiesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchPreferredCities = async () => {
    setLoading(true);
    const res = await get(`master/city-list`, "", {}, true);
    if (res?.status === true) {
      setPreferredCitiesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchLanguages = async () => {
    setLoading(true);
    const res = await get(`master/language-list`, "", {}, true);
    if (res?.status === true) {
      setLanguages(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchSkills = async () => {
    setLoading(true);
    const res = await get(`master/skills-list`, "", {}, true);
    if (res?.status === true) {
      setSkills(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (candidateFormData.country) {
      fetchStates();
    }
  }, [candidateFormData.country]);

  useEffect(() => {
    if (candidateFormData.state) {
      fetchCities();
    }
  }, [candidateFormData.state]);

  useEffect(() => {
    fetchCountries();
    fetchQualificationDropData();
    fetchDesignationDropData();
    fetchPreferredCities();
    fetchExperienceDropData();
    fetchSourceDropData();
    fetchLanguages();
    fetchSkills();
  }, []);

  const handleResetForm = async () => {
    setCandidateFormData({
      name: "",
      phoneNumber: "",
      aadharNumber: "",
      dateOfBirth: "",
      gender: "",
      language: "",
      skills: "",
      currentCompany: "",
      preferredLocation: "",
      annualSalary: "",
      qualification: "",
      designation: "",
      experience: "",
      source: "",
      pinCode: "",
      city: "",
      state: "",
      country: 1,
      cv: null,
    });
  };

  const handleSaveCandidate = async () => {
    setLoading(true);
    try {
      if (
        !candidateFormData?.name ||
        !candidateFormData?.phoneNumber ||
        !candidateFormData?.aadharNumber ||
        !candidateFormData?.dateOfBirth ||
        !candidateFormData?.gender ||
        candidateFormData?.annualSalary === "" ||
        !candidateFormData?.qualification ||
        !candidateFormData?.experience ||
        !candidateFormData?.source ||
        !candidateFormData?.country ||
        !candidateFormData?.state ||
        !candidateFormData?.city ||
        !candidateFormData?.pinCode
      ) {
        Toast({ message: "Please fill all required fields", type: "error" });
        setLoading(false);
        return;
      }
      let res;

      const formData = new FormData();
      Object.keys(candidateFormData).forEach((key) => {
        formData.append(key, candidateFormData[key]);
      });

      formData.set(
        "dateOfBirth",
        candidateFormData?.dateOfBirth
          ? candidateFormData.dateOfBirth.format(dateFormat)
          : ""
      );

      if (mode === "create") {
        res = await post(
          `candidate/create`,
          formData,
          { "Content-Type": "multipart/form-data" },
          true
        );
      } else if (mode === "update") {
        res = await put(
          `candidate/update/${id}`,
          formData,
          { "Content-Type": "multipart/form-data" },
          true
        );
      } else {
        res = await put(
          `candidate/self-update/${id}/${token}`,
          formData,
          { "Content-Type": "multipart/form-data" },
          false
        );
      }

      if (res?.status === true) {
        setCandidateFormData({
          name: "",
          phoneNumber: "",
          aadharNumber: "",
          dateOfBirth: "",
          gender: "",
          language: "",
          skills: "",
          currentCompany: "",
          preferredLocation: "",
          annualSalary: "",
          qualification: "",
          designation: "",
          experience: "",
          source: "",
          pinCode: "",
          city: "",
          state: "",
          country: 1,
          cv: null,
        });
        Toast({
          message: `Candidate ${
            mode === "create" ? "created" : "updated"
          } successfully`,
          type: "success",
        });
        if (mode === "update") {
          navigate("/search-candidates");
        }
        if (mode === "selfUpdate") {
          setIsSelfSuccess(true);
        }
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message:
          mode === "create"
            ? "Failed to create candidate"
            : "Failed to update candidate",
        type: "error",
      });
    }
  };

  const handleFileImport = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await post(
        `candidate/bulk-import`,
        formData,
        { "Content-Type": "multipart/form-data" },
        true
      );
      if (res?.status === true) {
        Toast({
          message: res?.message || "Imported successfully",
          type: "success",
        });
        window.location.reload(false);
      } else {
        Toast({
          message: res?.message || "Upload failed",
          type: "error",
        });
      }
      setLoading(false);
      return res.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      Toast({
        message: error.response?.message || "Upload failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const handleZipImport = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await post(
        `candidate/cv-bulk-import`,
        formData,
        { "Content-Type": "multipart/form-data" },
        true
      );
      if (res?.status === true) {
        Toast({
          message: res?.message || "Imported successfully",
          type: "success",
        });
        window.location.reload(false);
      } else {
        Toast({
          message: res?.message || "Upload failed",
          type: "error",
        });
      }
      setLoading(false);
      return res.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      Toast({
        message: error.response?.message || "Upload failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const fetchCandidate = async () => {
    setLoading(true);
    try {
      const response = await get(`candidate/list/${id}`, "", "", true);
      if (response?.status === true) {
        const candidate = response.data;
        setCandidateFormData({
          name: candidate.name,
          phoneNumber: candidate.phone_number,
          aadharNumber: candidate.aadhaar_card_number,
          dateOfBirth: moment(candidate.dob, dateFormat),
          gender: candidate.gender,
          language: candidate.language_id,
          skills: candidate.skills_id,
          currentCompany: candidate.current_company,
          preferredLocation: candidate.preffered_location_id,
          annualSalary: candidate.annual_salary,
          qualification: candidate.qualification_id,
          designation: candidate.designation_id,
          experience: candidate.experience_status_id,
          source: candidate.source_id,
          pinCode: candidate.pin_code,
          city: candidate.city_id,
          state: candidate.state_id,
          country: candidate.country_id,
          cv: candidate.cv,
        });
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSelfCandidateData = async () => {
    setLoading(true);
    try {
      const data = {
        candidate_profile_id: id,
        token: token,
      };
      const response = await post("candidate/self-data", data, "", false);
      if (response?.status === true) {
        const candidate = response.data;
        setCandidateFormData({
          name: candidate.name,
          phoneNumber: candidate.phone_number,
          aadharNumber: candidate.aadhaar_card_number,
          dateOfBirth: moment(candidate.dob, dateFormat),
          gender: candidate.gender,
          language: candidate.language_id,
          skills: candidate.skills_id,
          currentCompany: candidate.current_company,
          preferredLocation: candidate.preffered_location_id,
          annualSalary: candidate.annual_salary,
          qualification: candidate.qualification_id,
          designation: candidate.designation_id,
          experience: candidate.experience_status_id,
          source: candidate.source_id,
          pinCode: candidate.pin_code,
          city: candidate.city_id,
          state: candidate.state_id,
          country: candidate.country_id,
          cv: candidate.cv,
        });
        setLoading(false);
        setIsValidUrl(true);
      } else {
        setIsValidUrl(false);
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (mode === "update") {
      fetchCandidate();
    } else if (mode === "selfUpdate") {
      fetchSelfCandidateData();
    }
  }, []);

  return (
    <>
      {isValidUrl && !isSelfSuccess && (
        <div
          style={{
            backgroundColor: "#F2F2F2",
            padding: "30px 20px",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              paddingBottom: "20px",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <div
                style={{
                  fontSize: "22px",
                  fontWeight: 600,
                  lineHeight: "30px",
                  textAlign: "left",
                }}
              >
                {mode === "create" ? "Create Candidate" : "Update Candidate"}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "17px",
                  textAlign: "left",
                }}
              >
                Onboard new candidate by assigning them specific clients and
                work.
              </div>
            </div>

            {mode === "create" ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <div className="button-group">
                  <div>
                    <button
                      className="secondary-button"
                      title="Import bulk Recruiters"
                      onClick={() => {
                        setIsImportModalOpen(true);
                      }}
                    >
                      <img src={importIcon} alt="importIcon" />
                      Import Data
                    </button>
                  </div>
                </div>
                <div className="button-group">
                  <div>
                    <button
                      className="secondary-button"
                      title="Import bulk Recruiters"
                      onClick={() => {
                        setIsImportZipModalOpen(true);
                      }}
                    >
                      <img src={importIcon} alt="importIcon" />
                      Import CV
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ backgroundColor: "#FFFFFF", padding: "20px" }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="name">
                    Name <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="name"
                    placeholder="Enter Candidate Name"
                    className="candidate-create-input"
                    value={candidateFormData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="phoneNumber">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="phoneNumber"
                    placeholder="Enter Candidate Phone Number"
                    maxLength="10"
                    className="candidate-create-input"
                    value={candidateFormData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="aadharNumber">
                    Adhaar card Number <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="aadharNumber"
                    placeholder="Enter Candidate Aadhar card Number"
                    maxLength="12"
                    className="candidate-create-input"
                    value={candidateFormData.aadharNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="dateOfBirth">
                    Date of Birth <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    id="dateOfBirth"
                    disabledDate={(current) => {
                      return (
                        current && current > moment().subtract(10, "years")
                      );
                    }}
                    className="candidate-create-input"
                    format={dateFormat}
                    value={candidateFormData.dateOfBirth}
                    onChange={(date) => handleDateChange(date, "dateOfBirth")}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="gender">
                    Gender <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="gender"
                    placeholder="Select a Gender"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    options={genderData.map((zone) => ({
                      value: zone.value,
                      label: zone.label,
                      id: "gender",
                    }))}
                    value={candidateFormData.gender || undefined}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="language">Language</label>
                  <Select
                    id="language"
                    mode="multiple"
                    showSearch
                    className="candidate-select-input"
                    placeholder="select Languages"
                    onChange={(e) => {
                      handleSelectChange(e, { id: "language" });
                    }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={languages.map((language) => ({
                      value: language.id,
                      label: language.name,
                      id: "language",
                    }))}
                    value={candidateFormData.language || undefined}
                    maxTagCount={4}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="country">
                    Country <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="country"
                    placeholder="Select a Country"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    options={countriesDropData.map((country) => ({
                      value: country.id,
                      label: country.name,
                      id: "country",
                    }))}
                    value={candidateFormData.country || undefined}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="state">
                    State <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="state"
                    placeholder="Select a State"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={statesDropData.map((state) => ({
                      value: state.id,
                      label: state.name,
                      id: "state",
                    }))}
                    value={candidateFormData.state || undefined}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="city">
                    City <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="city"
                    placeholder="Select a City"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={citiesDropData.map((city) => ({
                      value: city.id,
                      label: city.name,
                      id: "city",
                    }))}
                    value={candidateFormData.city || undefined}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="pinCode">
                    Pin Code <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="pinCode"
                    placeholder="Enter Candidate Pin Code"
                    maxLength="6"
                    className="candidate-create-input"
                    value={candidateFormData.pinCode}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="preferredLocation">Preferred Location</label>
                  <Select
                    id="preferredLocation"
                    mode="multiple"
                    placeholder="Select a Preferred Location"
                    className="candidate-select-input"
                    onChange={(e) => {
                      handleSelectChange(e, { id: "preferredLocation" });
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={preferredCitiesDropData.map((preferredCity) => ({
                      value: preferredCity.id,
                      label: preferredCity.name,
                      id: "preferredLocation",
                    }))}
                    value={candidateFormData.preferredLocation || undefined}
                    style={{
                      height: "auto",
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="source">
                    Source <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="source"
                    placeholder="Select a Source"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    options={sourceDroData.map((source) => ({
                      value: source.id,
                      label: source.name,
                      id: "source",
                    }))}
                    value={candidateFormData.source || undefined}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="skills">Skills</label>
                  <Select
                    id="skills"
                    mode="multiple"
                    showSearch
                    className="candidate-select-input"
                    placeholder="select Skills"
                    onChange={(e) => {
                      handleSelectChange(e, { id: "skills" });
                    }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={skills.map((skill) => ({
                      value: skill.id,
                      label: skill.name,
                      id: "skills",
                    }))}
                    value={candidateFormData.skills || undefined}
                    style={{
                      height: "auto",
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="qualification">
                    Qualification <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="qualification"
                    placeholder="Select a Qualification"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={qualificationDropData.map((qualification) => ({
                      value: qualification.id,
                      label: qualification.name,
                      id: "qualification",
                    }))}
                    value={candidateFormData.qualification || undefined}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="experience">
                    Experience <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="experience"
                    placeholder="Select a Experience"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    options={experienceDropData.map((experience) => ({
                      value: experience.id,
                      label: experience.name,
                      id: "experience",
                    }))}
                    value={candidateFormData.experience || undefined}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="currentCompany">Current Company</label>
                  <Input
                    id="currentCompany"
                    placeholder="Enter Candidate Current Company"
                    className="candidate-create-input"
                    value={candidateFormData.currentCompany}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="designation">Designation</label>
                  <Select
                    id="designation"
                    placeholder="Select a Designation"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={designationDropData.map((designation) => ({
                      value: designation.id,
                      label: designation.name,
                      id: "designation",
                    }))}
                    value={candidateFormData.designation || undefined}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="annualSalary">
                    Annual Salary <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="annualSalary"
                    placeholder="Enter Candidate Annual Salary"
                    maxLength="10"
                    className="candidate-create-input"
                    value={candidateFormData.annualSalary}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="cv">CV</label>
                  <br />
                  <Upload
                    className="candidate-create-input-div"
                    accept=".pdf"
                    showUploadList={false}
                    customRequest={({ file, onSuccess }) => {
                      onSuccess("ok");
                      handleFileChange({ file });
                    }}
                  >
                    {/* <Button icon={<UploadOutlined />}>Upload CV (PDF only)</Button> */}
                    {/* <Button className="create-modal-cancel-button">
                  Choose File{" "}
                </Button> */}
                    <Button
                      icon={<UploadOutlined />}
                      className="candidate-create-input"
                    >
                      Upload CV
                    </Button>
                    {/* <br /> */}
                    {candidateFormData?.cv?.name}
                  </Upload>
                </div>
              </Col>
            </Row>

            <hr
              style={{
                backgroundColor: "#ced7de",
                height: "1px",
                border: "none",
              }}
            />

            <div style={{ display: "flex", justifyContent: "end", gap: "8px" }}>
              <Button
                className="create-modal-cancel-button"
                onClick={handleResetForm}
              >
                Reset{" "}
              </Button>
              <Button
                className="create-modal-confirm-button"
                onClick={handleSaveCandidate}
                disabled={loading}
              >
                {/* {loading ? "Creating..." : "Create"} */}
                {loading
                  ? mode === "create"
                    ? "Creating..."
                    : "Updating..."
                  : mode === "create"
                  ? "Create"
                  : "Update"}
              </Button>
            </div>
          </div>

          <ImportFileModal
            isVisible={isImportModalOpen}
            onClose={() => setIsImportModalOpen(false)}
            onImport={handleFileImport}
            downloadSampleSheetUrl="candidate/sample-sheet"
            title="Import Excel File"
          />

          <ImportZipModal
            isVisible={isImportZipModalOpen}
            onClose={() => setIsImportZipModalOpen(false)}
            onImport={handleZipImport}
            // downloadSampleSheetUrl="candidate/sample-sheet"
            title="Import Zip File"
          />

          <ToastContainer />
        </div>
      )}
      {isSelfSuccess && <SuccessPage />}
      {!isValidUrl && (
        // <div
        //   style={{
        //     backgroundColor: "#f5f6fb",
        //     width: "100vw",
        //     height: "100vh",
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <div>
        //     <img src={InvalidIcon} alt="icon" width="100px" />{" "}
        //   </div>
        //   <div
        //     style={{
        //       color: "#858a90",
        //       fontWeight: 600,
        //       fontSize: "20px",
        //     }}
        //   >
        //     Invalid URL
        //   </div>
        //   <div></div>
        // </div>
        <InvalidPage />
      )}
    </>
  );
};

export default CandidateForm;
