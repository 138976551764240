import React, { useEffect, useRef, useState } from "react";
import "../styles/SearchCandidatePage.css";
import { Button, Col, Flex, Input, Radio, Row, Select, Space, Tag } from "antd";
import {
  FreshersOnly,
  searchIcon,
  timeSearchIcon,
  User3,
  User,
} from "../assets";
import SVGClose from "../assets/SVGClose";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import useMediaQuery from "../hooks/useMediaQuery";
import Toast from "../components/Toast/Toast";
import { ToastContainer } from "react-toastify";
import { get } from "../lib/Api";
import { CloseOutlined } from "@ant-design/icons";

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={"#E5F8FF"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      closeIcon={<CloseOutlined style={{ color: "#0079A8" }} />}
      style={{
        marginInlineEnd: 10,
        color: "#0079A8",
        padding: "3px",
      }}
    >
      {label}
    </Tag>
  );
};

const SearchCandidatePage = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery("(max-width: 767px)");

  const [loading, setLoading] = useState(false);
  const [citiesDropData, setCitiesDropData] = useState([]);
  const [experienceDropData, setExperienceDropData] = useState([]);
  const [educationDropData, setEducationDropData] = useState([]);
  const [keywordInputValue, setKeywordInputValue] = useState("");

  const [searchData, setSearchData] = useState({
    // searchType: "any",
    keywords: [],
    currentCity: [],
    experience: [],
    minSalary: "",
    maxSalary: "",
    highestEducaton: [],
    industries: [],
    gender: "",
    languages: [],
  });

  const fetchCities = async () => {
    setLoading(true);
    const res = await get(`master/city-list`, {}, {}, true);
    if (res?.status === true) {
      setCitiesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchExperienceDropData = async () => {
    setLoading(true);
    const res = await get(`master/experience-list`, "", {}, true);
    if (res?.status === true) {
      setExperienceDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchEducationDropData = async () => {
    setLoading(true);
    const res = await get(`master/qualification-list`, "", {}, true);
    if (res?.status === true) {
      setEducationDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCities();
    fetchExperienceDropData();
    fetchEducationDropData();
  }, []);

  const handleSearchTypeChange = (e) => {
    setSearchData({
      ...searchData,
      searchType: e.target.value,
    });
  };

  const handleKeyPress = (e) => {
    if (
      (e.key === "Enter" && keywordInputValue.trim() !== "") ||
      (e.key === "Tab" && keywordInputValue.trim() !== "")
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      keywordInputValue.trim() !== ""
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [keywordInputValue]);

  const handleKeywordInputChange = (e) => {
    setKeywordInputValue(e.target.value);
  };

  const handleSelectChange = (value, option) => {
    setSearchData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleKeywordRemove = (index) => {
    setSearchData((prevData) => ({
      ...prevData,
      keywords: prevData.keywords.filter((_, i) => i !== index),
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === "minSalary" || id === "maxSalary") {
      if (/^[0-9]*$/.test(value) || value === "") {
        setSearchData({
          ...searchData,
          [id]: value,
        });
      }
    } else {
      setSearchData({
        ...searchData,
        [id]: value,
      });
    }
  };

  const hasValidSearchData = (data) => {
    return Object.values(data).some(value => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== "";
    });
  };

  const handleSearchCandidates = (event) => {
    event.preventDefault();

    if (hasValidSearchData(searchData)) {
      navigate("/candidates", { state: { searchData } });
    } else {
      Toast({ message: "Please fill in at least one field to show candidates", type: "error" });
    }
  };

  const handleResetData = async () => {
    setSearchData({
      // searchType: "any",
      keywords: [],
      currentCity: [],
      experience: [],
      minSalary: "",
      maxSalary: "",
      highestEducaton: [],
      industries: [],
      Gender: "",
      languages: [],
    });
  };

  return (
    <section className="search-candidate">
      <div className="header">
        <h2 className="header-title">Search Candidates</h2>
      </div>
      <Row gutter={[20, 20]} className="search-candidate-row">
        <Col xs={24} lg={15} xl={17}>
          <div className="header mobile-show-header">
            <h2 className="header-title">Search Candidates</h2>
          </div>
          <div className="search-bg-wrapper">
            {/* <div className="head space">
              <h3>Searching for</h3>
              <Radio.Group
                onChange={handleSearchTypeChange}
                value={searchData.searchType}
              >
                <Radio value={"fresher"}>
                  <p>
                    <img src={FreshersOnly} alt="" />
                    Freshers only
                  </p>
                </Radio>
                <Radio value={"experience"}>
                  <p>
                    <img src={User} alt="" />
                    Experienced only
                  </p>
                </Radio>
                <Radio value={"any"}>
                  <p>
                    <img src={User3} alt="" />
                    Any
                  </p>
                </Radio>
              </Radio.Group>
            </div> */}
            <div className="search-keyword space">
              <div className="form-group" ref={inputRef}>
                <label htmlFor="Keywords">
                  Keywords <span className="required">*</span>
                </label>
                <Input
                  id="Keywords"
                  placeholder="Type to search keyword"
                  value={keywordInputValue}
                  onChange={handleKeywordInputChange}
                  onKeyDown={handleKeyPress}
                />
              </div>

              <Space direction="horizontal" className="tags-wrapper">
                {searchData?.keywords.map((val, index) => (
                  <Tag
                    // closeIcon={<SVGClose />}
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      handleKeywordRemove(index);
                    }}
                    color="success"
                    key={index}
                  >
                    {val}
                  </Tag>
                ))}
              </Space>
            </div>
            <div className="search-keyword space">
              <div className="form-group">
                <label htmlFor="CurrentCity">Current city</label>
                {/* <Input id="CurrentCity" placeholder="Type to search city" /> */}

                <Select
                  id="currentCity"
                  mode="multiple"
                  placeholder="Select a Current City"
                  className="candidate-select-input"
                  onChange={(e) => {
                    handleSelectChange(e, { id: "currentCity" });
                  }}
                  // tagRender={tagRender}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={citiesDropData.map((city) => ({
                    value: city.id,
                    label: city.name,
                    id: "currentCity",
                  }))}
                  value={searchData.currentCity || undefined}
                  style={{
                    height: "auto",
                  }}
                />
              </div>
              {/* <Space direction="horizontal" className="tags-wrapper">
                <Tag
                  closeIcon={<SVGClose />}
                  onClose={console.log}
                  color="success"
                >
                  Ahmedabad
                </Tag>
                <Tag
                  closeIcon={<SVGClose />}
                  onClose={console.log}
                  color="success"
                >
                  Gurugram
                </Tag>
              </Space> */}
            </div>
            <div className="search-keyword space">
              <div className="form-group">
                <label htmlFor="Experience">Experience</label>
                <Row gutter={[16, 16]}>
                  <Col xs={24} xl={24}>
                    {/* <Select
                      defaultValue={
                        mediaQuery ? "Min. Exp" : "Minimum experience"
                      }
                      // onChange={console.log("Selected contract type")}
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "yiminghe" },
                      ]}
                    /> */}
                    <Select
                      id="experience"
                      mode="multiple"
                      placeholder="Select a Experience"
                      className="candidate-select-input"
                      onChange={(e) => {
                        handleSelectChange(e, { id: "experience" });
                      }}
                      // tagRender={tagRender}
                      showSearch
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={experienceDropData.map((experience) => ({
                        value: experience.id,
                        label: experience.name,
                        id: "experience",
                      }))}
                      value={searchData.experience || undefined}
                      style={{
                        height: "auto",
                      }}
                    />
                  </Col>
                  {/* <Col xs={12} xl={12}>
                    <Select
                      defaultValue={
                        mediaQuery ? "Max. Exp" : "Maximum experience"
                      }
                      // onChange={console.log("Selected contract type")}
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "yiminghe" },
                      ]}
                    />
                  </Col> */}
                </Row>
              </div>
              <div className="form-group">
                <label htmlFor="AnnualSalary">Annual Salary</label>
                <Row gutter={[16, 16]}>
                  <Col xs={12} xl={12}>
                    {/* <Select
                      defaultValue={
                        mediaQuery ? "Min. in lakhs" : "Minimum salary in lakhs"
                      }
                      // onChange={console.log("Selected contract type")}
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "yiminghe" },
                      ]}
                    /> */}

                    <Input
                      id="minSalary"
                      placeholder={
                        mediaQuery ? "Min. in lakhs" : "Minimum salary in lakhs"
                      }
                      value={searchData.minSalary}
                      onChange={handleInputChange}
                      // onKeyDown={handleKeyPress}
                    />
                  </Col>
                  <Col xs={12} xl={12}>
                    {/* <Select
                      defaultValue={
                        mediaQuery ? "Max. in lakhs" : "Maximum salary in lakhs"
                      }
                      // onChange={console.log("Selected contract type")}
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "yiminghe" },
                      ]}
                    /> */}
                    <Input
                      id="maxSalary"
                      placeholder={
                        mediaQuery ? "Max. in lakhs" : "Maximum salary in lakhs"
                      }
                      value={searchData.maxSalary}
                      onChange={handleInputChange}
                      // onKeyDown={handleKeyPress}
                    />
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label htmlFor="highestEducaton">Highest Education</label>
                <Select
                  id="highestEducaton"
                  mode="multiple"
                  placeholder="Select a Education"
                  className="candidate-select-input"
                  onChange={(e) => {
                    handleSelectChange(e, { id: "highestEducaton" });
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={educationDropData.map((education) => ({
                    value: education.id,
                    label: education.name,
                    id: "highestEducaton",
                  }))}
                  value={searchData.highestEducaton || undefined}
                  style={{
                    height: "auto",
                  }}
                />

                {/* <Space direction="horizontal" className="tags-wrapper">
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    10th pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                  <Tag
                    closeIcon={<PlusOutlined />}
                    onClose={console.log}
                    color="default"
                  >
                    12th Pass
                  </Tag>
                </Space> */}

                {/* <Button type="link" block>
                  View More
                </Button> */}
              </div>
            </div>
            <div className="search-bg-footer">
              <div className="candidate-right-head">
                <Flex
                  justify="space-between"
                  align="center"
                  wrap
                  style={{ gap: "10px" }}
                >
                  <Flex
                    align="center"
                    style={{ gap: "16px" }}
                    className="active-in"
                  >
                    {/* <label>Active in</label>
                    <Select
                      defaultValue="6 months"
                      style={{
                        width: "100%",
                      }}
                      options={[
                        {
                          value: "1",
                          label: "1 Years",
                        },
                        {
                          value: "2",
                          label: "2 Years",
                        },
                      ]}
                    /> */}{" "}
                    &nbsp;
                  </Flex>
                  <Flex align="center" style={{ gap: "16px" }}>
                    <Button type="text" size="large" onClick={handleResetData}>
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      icon={<img src={searchIcon} alt="icon" />}
                      onClick={handleSearchCandidates}
                    >
                      Search Candidates
                    </Button>
                  </Flex>
                </Flex>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={9} xl={7}>
          <div className="search-candidate-right">
            <div className="search-bg-wrapper mobile-search-wrapper">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={timeSearchIcon} alt="" />
                  Recent searches
                </h3>
                <Button type="link" block>
                  View All
                </Button>
              </Flex>
              <div className="candidate-recent-search">
                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>

                <ul>
                  <li>Marketing Head | Anywhere in India</li>
                  <li>
                    <div>
                      <Button type="link" block>
                        Fill search
                      </Button>
                      <Button type="link" block>
                        Search candidates
                      </Button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </section>
  );
};

export default SearchCandidatePage;
