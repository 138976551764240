import { Outlet, useNavigate } from "react-router-dom";
import { menuIcon, swagathamLogo } from "../../assets";
import "../../styles/MainHeader.css";
import { Button, Dropdown, Modal } from "antd";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useState } from "react";

function MainHeader({ handleMenu }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            alert("Pending Work ");
          }}
          rel="noopener noreferrer"
        >
          Reset Password
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            setIsLogoutModalOpen(true);
          }}
          rel="noopener noreferrer"
        >
          Log Out
        </a>
      ),
    },
  ];

  return (
    <>
      <div className="main-header">
        <div className="header-left">
          <img
            // src={menuIcon}
            src='menu-icon.svg'
            alt="Menu Icon"
            className="menu-icon"
            onClick={handleMenu}
          />
          <img
            src='swagatham-logo.png'
            alt="Swagatham Logo"
            className="logo"
          />
        </div>
        <Dropdown menu={{ items }} placement="bottomRight">
          <div className="header-right">OR</div>
        </Dropdown>
      </div>

      <Outlet />

      <ConfirmationModal
        isOpen={isLogoutModalOpen}
        onConfirm={handleLogout}
        onCancel={() => setIsLogoutModalOpen(false)}
        loading={loading}
        title="Confirm Log out"
        message={`Are you sure you want to log out of this device?`}
      />
    </>
  );
}

export default MainHeader;
