import React, { useEffect, useState } from "react";
import {
  createRecruiterIcon,
  editIcon,
  exportIcon,
  filterIcon,
  importIcon,
  mailCheckIcon,
  rightUserIcon,
  tableDeleteIcon,
  tableEditIcon,
  twoUserIcon,
  userRoundXIcon,
} from "../assets";
import { Button, DatePicker, Input, Modal, Select, Space, Table } from "antd";
import "../styles/JobsPage.css";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Toast from "../components/Toast/Toast";
import { get, post, put } from "../lib/Api";
import StatusBadge from "../components/table/StatusBadge";
import ImportFileModal from "../components/modal/ImportFileModal ";
import { ToastContainer } from "react-toastify";
import ConfirmationModal from "../components/modal/ConfirmationModal";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const JobsPage = () => {
  const [data, setData] = useState();
  const [clientsDropData, setClientsDropData] = useState([]);
  const [industriesDropData, setIndustriesDropData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  const [profileData, setProfileData] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const dateFormat = "DD/MM/YYYY";

  const [contractType, setContractType] = useState([]);

  const [zones, setZones] = useState([
    { value: "North", label: "North" },
    { value: "East", label: "East" },
    { value: "South", label: "South" },
    { value: "West", label: "West" },
  ]);

  const [jobFormData, setJobFormData] = useState({
    client: "",
    jobTitle: "",
    location: "",
    zone: "",
    skills: [],
    industry: "",
    startDate: "",
    endDate: "",
    minCtc: "",
    maxCtc: "",
    minExp: "",
    maxExp: "",
    numberOfPosition: "",
    contractType: "",
  });

  const [jobtCount, setJobCount] = useState({
    totalJobs: "",
    activeJobs: "",
    inactiveJobs: "",
    invitedJobs: "",
  });

  const [jobExportData, setJobExportData] = useState({
    client: "",
  });

  const [open, setOpen] = useState(false);
  const showCreateJobModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const [profileOpen, setProfileOpen] = useState(false);
  const showProfileModal = () => {
    setProfileOpen(true);
  };
  const handleProfileOk = () => {
    setProfileOpen(false);
  };
  const handleProfileCancel = () => {
    setProfileOpen(false);
  };

  const openImportModal = () => {
    setIsImportModalOpen(true);
  };
  const handleImportCancel = () => {
    setIsImportModalOpen(false);
    setFile(null);
  };

  const handleExportStateChange = (value, option) => {
    setJobExportData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
    },
  });

  const columns = [
    {
      title: "Job Title",
      sorter: true,
      key: "job_title",
      render: (result) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div><img src={profileUserIcon} alt="User Icon"/></div> */}
          <div
            style={{ paddingLeft: "10px", cursor: "pointer" }}
            onClick={() => {
              showProfileModal();
              setProfileData(result);
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#00adf0"; // Change background color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "black"; // Reset background color on hover out
            }}
          >
            <div
              style={{ fontWeight: 500, fontSize: "14px", lineHeight: "17px" }}
            >{`${result.job_title}`}</div>
            {/* <div
              style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}
            >
              {result.email}
            </div> */}
          </div>
        </div>
      ),
      width: "25%",
    },
    {
      title: "Job Code",
      dataIndex: "job_code",
      width: "10%",
      align: "center",
      key: "job_code",
    },
    {
      title: "Company",
      dataIndex: "client",
      width: "15%",
      align: "center",
      key: "client",
    },
    {
      title: "Location",
      dataIndex: "location",
      width: "10%",
      align: "center",
      key: "location",
    },
    {
      title: "Number of Position",
      dataIndex: "number_of_open_position",
      width: "15%",
      align: "center",
      key: "number_of_open_position",
      render: (result) => <>{result === null ? "-" : result}</>,
    },
    {
      title: "Zone",
      dataIndex: "zone",
      width: "15%",
      align: "center",
      key: "zone",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      width: "15%",
      align: "center",
      key: "created_at",
      sorter: true,
      render: (result) => <>{moment(result).format("DD-MM-YYYY")}</>,
    },
    {
      title: "Status",
      // dataIndex: "is_active",
      align: "center",
      key: "status",
      render: (result) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          title="Change the Status"
          onClick={() => {
            setIsStatusModalOpen(true);
            setProfileData(result);
          }}
        >
          <StatusBadge
            status={result.is_active === true ? "Active" : "Inactive"}
          />
          <div></div>
        </div>
      ),
      width: "10%",
    },
    {
      title: "",
      render: (result) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            title="Edit Job"
            onClick={() => {
              setProfileData(result);
              setJobFormData({
                client: result?.client_id,
                jobTitle: result?.job_title,
                location: result?.location,
                zone: result?.zone,
                skills: result?.skills_id,
                industry: result?.industry_id,
                startDate: result?.start_date
                  ? moment(result?.start_date, dateFormat)
                  : "",
                endDate: result?.end_date
                  ? moment(result?.end_date, dateFormat)
                  : "",
                minCtc: result?.min_ctc,
                maxCtc: result?.max_ctc,
                minExp: result?.min_experience,
                maxExp: result?.max_experience,
                numberOfPosition: result?.number_of_open_position,
                contractType: result?.contract_type_id,
              });
              showCreateJobModal();
              setProfileOpen(false);
              setEditModalVisible(true);
            }}
          >
            <img src={tableEditIcon} alt="Edit" />
          </div>
          <div style={{ cursor: "pointer" }} title="Delete Job">
            <img
              src={tableDeleteIcon}
              alt="Delete"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setProfileData({ ...profileData, id: result.id });
              }}
            />
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const getRandomuserParams = (params) => ({
    // results: params.pagination?.pageSize,
    page_no: params.pagination?.current,
    page_size: params.pagination?.pageSize,
    query: debouncedValue,
    status: selectedStatus,
    sort_order: params.sortOrder,
    // ...params,
  });

  const fetchData = async () => {
    setLoading(true);
    const res = await get(
      `job/list`,
      getRandomuserParams(tableParams),
      {},
      true
    );
    if (res?.status === true) {
      setData(res.job_posting_list.results);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.count,
        },
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchJobCount = async () => {
    setLoading(true);
    const res = await get(`job/count`, null, {}, true);
    if (res?.status === true) {
      setJobCount({
        totalJobs: res.data.total,
        activeJobs: res.data.active,
        inactiveJobs: res.data.inactive,
        invitedJobs: res.data.invited,
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchClientsDropData = async () => {
    setLoading(true);
    const res = await get(`client/dropdown`, "", {}, true);
    if (res?.status === true) {
      setClientsDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchIndustriesDropData = async () => {
    setLoading(true);
    const res = await get(`master/industry-list`, "", {}, true);
    if (res?.status === true) {
      setIndustriesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchContractTypeData = async () => {
    setLoading(true);
    const res = await get(`master/contract-type-list`, "", {}, true);
    if (res?.status === true) {
      setContractType(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchSkills = async () => {
    setLoading(true);
    const res = await get(`master/skills-list`, "", {}, true);
    if (res?.status === true) {
      setSkills(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    fetchData();
    fetchJobCount();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    debouncedValue,
    selectedStatus,
  ]);

  useEffect(() => {
    fetchClientsDropData();
    fetchIndustriesDropData();
    fetchContractTypeData();
    fetchSkills();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === "minCtc" || id === "maxCtc" || id === "numberOfPosition") {
      if (/^[0-9]*$/.test(value) || value === "") {
        setJobFormData({
          ...jobFormData,
          [id]: value,
        });
      }
    } else {
      setJobFormData({
        ...jobFormData,
        [id]: value,
      });
    }
  };

  const handleStateChange = (value, option) => {
    setJobFormData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleDateChange = (date, id) => {
    setJobFormData({
      ...jobFormData,
      [id]: date,
    });
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleFileImport = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await post(
        `job/bulk-import`,
        formData,
        { "Content-Type": "multipart/form-data" },
        true
      );

      Toast({
        message: res?.message || "Imported successfully",
        type: "success",
      });
      fetchData();
      fetchJobCount();
      setLoading(false);
      return res.data;
    } catch (error) {
      Toast({
        message: error.response?.data?.message || "Upload failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters,
      sortOrder: Array.isArray(sorter)
        ? undefined
        : `${sorter.columnKey}: ${sorter.order}`,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSaveJob = async () => {
    setLoading(true);

    try {
      if (
        !jobFormData?.client ||
        !jobFormData?.jobTitle ||
        !jobFormData?.location ||
        !jobFormData?.zone ||
        !jobFormData?.industry ||
        !jobFormData?.startDate ||
        jobFormData?.minCtc === "" ||
        jobFormData?.maxCtc === "" ||
        jobFormData?.minExp === "" ||
        jobFormData?.maxExp === "" ||
        jobFormData?.numberOfPosition === ""
      ) {
        Toast({ message: "Please fill all required files", type: "error" });
        setLoading(false);
        return;
      }
      let res;
      if (editModalVisible) {
        // Update client
        const data = {
          ...jobFormData,
          startDate: jobFormData?.startDate
            ? jobFormData.startDate.format(dateFormat)
            : "",
          endDate: jobFormData?.endDate
            ? jobFormData.endDate.format(dateFormat)
            : "",
          id: profileData.id,
        };
        res = await put(`job/update`, data, {}, true);
        if (res?.status === true) {
          Toast({ message: "Job updated successfully", type: "success" });
        } else {
          Toast({ message: res?.message, type: "error" });
        }
      } else {
        const data = {
          ...jobFormData,
          startDate: jobFormData?.startDate
            ? jobFormData.startDate.format(dateFormat)
            : "",
          endDate: jobFormData?.endDate
            ? jobFormData.endDate.format(dateFormat)
            : "",
        };
        res = await post(`job/create`, data, {}, true);
        if (res?.status === true) {
          Toast({ message: "Job created successfully", type: "success" });
        } else {
          Toast({ message: res?.message, type: "error" });
        }
      }

      if (res?.status === true) {
        setJobFormData({
          client: "",
          jobTitle: "",
          location: "",
          zone: "",
          skills: [],
          industry: "",
          startDate: "",
          endDate: "",
          minCtc: "",
          maxCtc: "",
          minExp: "",
          maxExp: "",
          numberOfPosition: "",
          contractType: "",
        });
        fetchData();
        fetchJobCount();
        setOpen(false);
        setEditModalVisible(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to ${editModalVisible ? "update" : "create"} job`,
        type: "error",
      });
    }
  };

  const handleDeleteJob = async () => {
    setLoading(true);
    try {
      let res;

      const data = { id: profileData.id };
      res = await put(`job/delete`, data, {}, true);
      if (res?.status === true) {
        Toast({ message: "Job Deleted successfully", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        fetchData();
        fetchJobCount();
        setIsDeleteModalOpen(false);
        setProfileOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to Job Delete`,
        type: "error",
      });
    }
  };

  const handleStatusJob = async () => {
    setLoading(true);
    try {
      let res;

      const data = { id: profileData.id };
      res = await put(`job/status`, data, {}, true);
      if (res?.status === true) {
        Toast({
          message: "Job Status successfully changed",
          type: "success",
        });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        fetchData();
        fetchJobCount();
        setIsStatusModalOpen(false);
        setProfileOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to change status for this job`,
        type: "error",
      });
    }
  };

  const handleExportData = async () => {
    setLoading(true);
    try {
      if (!jobExportData.client) {
        Toast({ message: "Please fill all required files", type: "error" });
        setLoading(false);
        return;
      }
      let res;
      res = await post(`job/bulk-export`, jobExportData, {}, true);
      if (res?.status === true) {
        setJobExportData({
          client: "",
        });
        const element = document.createElement("a");
        element.href = baseUrl + res.file_path;
        element.download = "";
        element.click();
        setIsExportModalOpen(false);
        Toast({ message: "Report successfully downloaded", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: "Network Error",
        type: "error",
      });
    }
  };

  return (
    <div className="rcontainer">
      <div className="header">
        <div>
          <div className="header-title">Jobs</div>
          <div className="header-subtitle">
            Onboard new jobs by assigning the specific client and recruiter
          </div>
        </div>
        <div className="button-group">
          <div>
            <button
              className="secondary-button"
              title="Export Job's Data"
              onClick={() => {
                setIsExportModalOpen(true);
              }}
            >
              <img src={exportIcon} alt="exportIcon" />
              Export
            </button>
          </div>
          <div>
            <button
              className="secondary-button"
              title="Import Job's Data"
              onClick={openImportModal}
            >
              <img src={importIcon} alt="importIcon" />
              Import
            </button>
          </div>
          <div>
            <button
              className="jprimary-button"
              onClick={() => {
                showCreateJobModal();
                setEditModalVisible(false);
                setJobFormData({
                  client: "",
                  jobTitle: "",
                  location: "",
                  zone: "",
                  skills: [],
                  industry: "",
                  startDate: "",
                  endDate: "",
                  minCtc: "",
                  maxCtc: "",
                  minExp: "",
                  maxExp: "",
                  numberOfPosition: "",
                  contractType: "",
                });
              }}
            >
              <img src={createRecruiterIcon} alt="createJobIcon" />
              Create Job
            </button>
          </div>
        </div>
      </div>

      <div className="stats-cards">
        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Total Jobs</div>
            <div className="value">{jobtCount?.totalJobs}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={rightUserIcon} alt="rightUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Active Jobs</div>
            <div className="value">{jobtCount?.activeJobs}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={userRoundXIcon} alt="userRoundXIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Inactive Jobs</div>
            <div className="value">{jobtCount?.inactiveJobs}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={mailCheckIcon} alt="mailCheckIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Invited</div>
            <div className="value">{jobtCount?.invitedJobs}</div>
          </div>
        </div>
      </div>

      <div className="filter-section">
        <div className="filter-bar">
          <div className="filter-group">
            <div>
              <Input
                placeholder="Search by Job title, Job code or Client Name"
                className="search-input"
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "20px",
                    }}
                  />
                }
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div>
              <Select
                defaultValue="all"
                className="filter-select"
                onChange={handleStatusChange}
                options={[
                  { value: "all", label: "All" },
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
              />
            </div>
          </div>
          {/* <div>
            <button className="filter-button">
              <img src={filterIcon} alt="Filter Icon" /> <span>Filter</span>
            </button>
          </div> */}
        </div>
        <div className="table-container">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <Modal
        open={open}
        width={760}
        maskClosable={false}
        title="Create Job"
        onOk={showCreateJobModal}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className="create-modal-cancel-button"
              onClick={() => {
                setOpen(false);
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              className="create-modal-confirm-button"
              onClick={handleSaveJob}
              disabled={loading}
            >
              {editModalVisible
                ? loading
                  ? "Updating..."
                  : "Update"
                : loading
                ? "Creating..."
                : "Create"}
            </Button>
          </>
        )}
      >
        <hr
          style={{ backgroundColor: "#ced7de", height: "1px", border: "none" }}
        />
        <div className="job-create-raw">
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="client">
              Client <span className="text-danger">*</span>
            </label>
            <Select
              id="client"
              placeholder="Select a Client"
              className="client-create-select-input"
              onChange={handleStateChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={clientsDropData.map((client) => ({
                value: client.id,
                label: client.name,
                id: "client",
              }))}
              value={jobFormData.client || undefined}
            />
          </div>
          <div className="job-create-input-div">
            <label htmlFor="jobTitle">
              Job Title <span className="text-danger">*</span>
            </label>
            <Input
              id="jobTitle"
              placeholder="Enter Job Title"
              className="client-create-input"
              value={jobFormData.jobTitle}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="job-create-raw" style={{ paddingTop: "15px" }}>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="location">
              Location <span className="text-danger">*</span>
            </label>
            <Input
              id="location"
              placeholder="Enter Location"
              className="client-create-input"
              value={jobFormData.location}
              onChange={handleInputChange}
            />
          </div>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="zone">
              Zone <span className="text-danger">*</span>
            </label>
            <Select
              id="zone"
              placeholder="Select a Zone"
              className="job-create-select-input"
              onChange={handleStateChange}
              options={zones.map((zone) => ({
                value: zone.value,
                label: zone.label,
                id: "zone",
              }))}
              value={jobFormData.zone || undefined}
            />
          </div>
        </div>

        <div className="job-create-raw" style={{ paddingTop: "15px" }}>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="skills">Skills</label>
            <Select
              id="skills"
              mode="multiple"
              showSearch
              className="candidate-select-input"
              placeholder="select Skills"
              onChange={(e) => {
                handleStateChange(e, { id: "skills" });
              }}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              options={skills.map((skill) => ({
                value: skill.id,
                label: skill.name,
                id: "skills",
              }))}
              value={jobExportData.skills || undefined}
              maxTagCount={3}
            />
          </div>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="industry">
              Industry <span className="text-danger">*</span>
            </label>
            <Select
              id="industry"
              placeholder="Select a Industry"
              className="client-create-select-input"
              onChange={handleStateChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={industriesDropData.map((industry) => ({
                value: industry.id,
                label: industry.name,
                id: "industry",
              }))}
              value={jobFormData.industry || undefined}
            />
          </div>
        </div>

        <div className="job-create-raw" style={{ paddingTop: "15px" }}>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="startDate">
              Start Date <span className="text-danger">*</span>
            </label>
            <br />
            <DatePicker
              id="startDate"
              // disabledDate={(current) => {
              //   const start = moment("02/07/2024", dateFormat);
              //   // return current < start;
              // }}
              disabledDate={(current) => {
                return current && current > moment().endOf("day");
              }}
              className="job-create-date-input"
              format={dateFormat}
              value={jobFormData.startDate}
              onChange={(date) => handleDateChange(date, "startDate")}
            />
          </div>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="endDate">End Date</label>
            <DatePicker
              id="endDate"
              disabledDate={(current) => {
                // const start = moment("02/07/2024", dateFormat);
                return current < jobFormData.startDate;
              }}
              className="job-create-date-input"
              format={dateFormat}
              value={jobFormData.endDate}
              onChange={(date) => handleDateChange(date, "endDate")}
            />
          </div>
        </div>

        <div className="job-create-raw" style={{ paddingTop: "15px" }}>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="minCtc">
              Min. CTC <span className="text-danger">*</span>
            </label>
            <Input
              id="minCtc"
              placeholder="Enter Min Ctc"
              className="client-create-input"
              value={jobFormData.minCtc}
              onChange={handleInputChange}
            />
          </div>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="maxCtc">
              Max. CTC <span className="text-danger">*</span>
            </label>
            <Input
              id="maxCtc"
              placeholder="Enter Max Ctc"
              className="client-create-input"
              value={jobFormData.maxCtc}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="job-create-raw" style={{ paddingTop: "15px" }}>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="minExp">
              Min. Experience <span className="text-danger">*</span>
            </label>
            <Input
              id="minExp"
              placeholder="Enter Min Experience"
              maxLength="2"
              className="client-create-input"
              value={jobFormData.minExp}
              onChange={handleInputChange}
            />
          </div>
          <div className="job-create-input-div">
            {" "}
            <label htmlFor="maxExp">
              Max. Experience <span className="text-danger">*</span>
            </label>
            <Input
              id="maxExp"
              maxLength="2"
              placeholder="Enter Max Experience"
              className="client-create-input"
              value={jobFormData.maxExp}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="job-create-raw" style={{ paddingTop: "15px" }}>
          <div className="job-create-input-div">
            <label htmlFor="numberOfPosition">
              Number of open position <span className="text-danger">*</span>
            </label>
            <Input
              id="numberOfPosition"
              placeholder="Enter Number of position"
              className="client-create-input"
              value={jobFormData.numberOfPosition}
              onChange={handleInputChange}
            />
          </div>
          <div className="job-create-input-div">
            <label htmlFor="contractType">Contract type</label>
            <Select
              id="contractType"
              placeholder="Select a Contract Type"
              className="job-create-select-input"
              onChange={handleStateChange}
              options={contractType.map((contract) => ({
                value: contract.id,
                label: contract.name,
                id: "contractType",
              }))}
              value={jobFormData.contractType || undefined}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={profileOpen}
        width={486}
        height={1000}
        className="ant-modal-content-modify"
        style={{
          position: "fixed",
          top: "-7px",
          right: "-7px",
          backgroundColor: "#FFFFFF",
          padding: 0,
        }}
        title=" &nbsp; &nbsp;  View Job Details"
        onOk={handleProfileOk}
        onCancel={handleProfileCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <div className="profile-footer">
            {/* <Button
              className="job-reset-password-button"
              onClick={() => {
                alert("Okay");
              }}
            >
              {" "}
              Reset password{" "}
            </Button> */}
            <Button
              className="job-delete-button"
              onClick={() => {
                setIsDeleteModalOpen(true);
                // setProfileData({ ...profileData, id: result.id });
              }}
            >
              {" "}
              Delete job{" "}
            </Button>
          </div>
        )}
      >
        <div className="jprofile-main-details">
          {/* <div style={{ flex: 1 }}>
            <img src={profileUserIcon} alt="User" width="80px" height="80px" />
          </div> */}
          <div style={{ flex: 3 }}>
            <div style={{ display: "flex", gap: "6px" }}>
              <div className="profile-name-text">
                {" "}
                {profileData?.job_title}{" "}
              </div>

              {profileData?.is_active === true ? (
                <div
                  className="active-lable"
                  onClick={() => {
                    setIsStatusModalOpen(true);
                  }}
                >
                  Active
                </div>
              ) : (
                <div
                  className="inactive-lable"
                  onClick={() => {
                    setIsStatusModalOpen(true);
                  }}
                >
                  Inactive
                </div>
              )}
            </div>
            {/* <div className="profile-content-div">
              {" "}
              <div className="profile-icon">
                <img src={mailIcon} alt="Mail Icon" />
              </div>{" "}
              <div className="profile-content-"> C : {profileData?.client}</div>
            </div> 
             <div className="profile-content-div">
              {" "}
               <div className="profile-icon">
                <img src={phoneIcon} alt="Phone Icon" />
              </div>{" "} 
              <div className="profile-content-">
                P : {profileData?.number_of_open_position}
              </div>
            </div> */}
          </div>
        </div>
        <div style={{ height: "670px" }}>
          <div className="profile-details-div">
            Job Details{" "}
            <button
              className="job-edit-button"
              onClick={() => {
                setJobFormData({
                  client: profileData?.client_id,
                  jobTitle: profileData?.job_title,
                  location: profileData?.location,
                  zone: profileData?.zone,
                  skills: profileData?.skills_id,
                  industry: profileData?.industry_id,
                  startDate: profileData?.start_date
                    ? moment(profileData?.start_date, dateFormat)
                    : "",
                  endDate: profileData?.end_date
                    ? moment(profileData?.end_date, dateFormat)
                    : "",
                  minCtc: profileData?.min_ctc,
                  maxCtc: profileData?.max_ctc,
                  minExp: profileData?.min_experience,
                  maxExp: profileData?.max_experience,
                  numberOfPosition: profileData?.number_of_open_position,
                  contractType: profileData?.contract_type_id,
                });
                showCreateJobModal();
                setProfileOpen(false);
                setEditModalVisible(true);
              }}
            >
              <img src={editIcon} alt="Edit Icon" /> Edit
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 20px",
              gap: "4px",
            }}
          >
            <div>
              <div className="details-role">Client</div>
              <div className="details-role">Job Title</div>
              <div className="details-role">Location</div>
              <div className="details-role">Zone</div>
              <div className="details-role">Skills</div>
              <div className="details-role">Industry</div>
              <div className="details-role">Start Date</div>
              <div className="details-role">End Date</div>
              <div className="details-role">Min. CTC</div>
              <div className="details-role">Max. CTC</div>
              <div className="details-role">Min. Experience</div>
              <div className="details-role">Max. Experience</div>
              <div className="details-role">Number Of Position</div>
              <div className="details-role">Contract Type</div>
            </div>
            <div>
              <div className="details-role-name">
                : &nbsp; {profileData?.client || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.job_title || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.location || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.zone || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.skills_name?.join(", ") || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.industry || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.start_date || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.end_date || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.min_ctc}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.max_ctc}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.min_experience}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.max_experience}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.number_of_open_position || "N/A"}
              </div>
              <div className="details-role-name">
                : &nbsp; {profileData?.contract_type || "N/A"}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </Modal>

      <ImportFileModal
        isVisible={isImportModalOpen}
        onClose={handleImportCancel}
        onImport={handleFileImport}
        downloadSampleSheetUrl="job/sample-sheet"
        title="Import Excel File"
      />

      <Modal
        open={isExportModalOpen}
        width={600}
        maskClosable={false}
        title="Export Job Data"
        onOk={() => {
          setIsExportModalOpen(false);
        }}
        onCancel={() => {
          setIsExportModalOpen(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className="create-modal-cancel-button"
              onClick={() => {
                setIsExportModalOpen(false);
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              className="create-modal-confirm-button"
              onClick={handleExportData}
              disabled={loading}
            >
              {loading ? "Exporting..." : "Export"}
            </Button>
          </>
        )}
      >
        <hr
          style={{ backgroundColor: "#ced7de", height: "1px", border: "none" }}
        />

        <div className="job-export-raw" style={{ paddingTop: "15px" }}>
          <div className="job-export-input-div">
            {" "}
            <label htmlFor="client">
              Client <span className="text-danger">*</span>{" "}
            </label>
            <Select
              id="client"
              placeholder="Select a client"
              className="job-export-input"
              onChange={handleExportStateChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={clientsDropData.map((client) => ({
                value: client.id,
                label: client.name,
                id: "client",
              }))}
              value={setJobExportData.client || undefined}
            />
          </div>
        </div>
      </Modal>

      <ConfirmationModal
        isOpen={isStatusModalOpen}
        onConfirm={handleStatusJob}
        onCancel={() => setIsStatusModalOpen(false)}
        loading={loading}
        title="Confirm Status Change"
        message={`Are you sure you want to change ${
          profileData?.job_title
        }'s status to ${
          profileData.is_active == true ? "inactive" : "active"
        }?`}
      />

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onConfirm={handleDeleteJob}
        onCancel={() => setIsDeleteModalOpen(false)}
        loading={loading}
        title="Confirm Delete"
        message="Are you sure you want to delete this job?"
      />

      <ToastContainer />
    </div>
  );
};

export default JobsPage;
