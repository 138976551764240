import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Input,
  Modal,
  Row,
} from "antd";
import { UserOutlined, RightOutlined } from "@ant-design/icons";
import {
  ClockIcon,
  CommentPlusIcon,
  Downolad1Icon,
  FileUploadIcon,
  UnlockIcon,
  bagIcon,
  bilidingIcon,
  bookIcon,
  mail1Icon,
  mapIcon,
  phone1Icon,
  walletIcon,
} from "../../assets";
import "../../styles/candidate-card.css";
import ConfirmationModal from "../modal/ConfirmationModal";
import Toast from "../Toast/Toast";
import { ToastContainer } from "react-toastify";
import { post } from "../../lib/Api";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const CandidateCard = ({
  candidate,
  handleVisibleDetails,
  isSelected,
  onSelectChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [isCvDownloadModalOpen, setIsCvDownloadModalOpen] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const handlePhoneNumberClick = () => {
    setShowPhoneNumber(!showPhoneNumber);
  };

  const handleDownloadCV = async () => {
    try {
      const data = {
        candidate_profile_id: candidate?.id,
      };
      const response = await post("candidate/cv-download", data, "", true);
      if (response?.status === true) {
        const url = baseUrl + response?.data?.cv_path;
        window.open(url, "_blank");
        setIsCvDownloadModalOpen(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to Download CV", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCommentModal = () => {
    setShowCommentModal(false);
    setCommentText("");
  };

  const handlePostComment = async () => {
    setLoading(true);
    const data = {
      candidate_profile_id: candidate?.id,
      note_description: commentText,
    };
    try {
      const response = await post("candidate/notes-create", data, "", true);
      if (response?.status === true) {
        Toast({ message: response?.message, type: "success" });
        setShowCommentModal(false);
        setCommentText("");
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        title={
          <>
            <Flex
              justify="space-between"
              align="center"
              className="candidate-user-info"
            >
              <Checkbox checked={isSelected} onChange={onSelectChange}>
                <div onClick={() => handleVisibleDetails(candidate)}>
                  <Avatar icon={<UserOutlined />} />
                  <h2>{candidate.name}</h2> <RightOutlined />
                </div>
              </Checkbox>
            </Flex>
            <div className="candidate-user-bio">
              <div className="user-bio-details">
                <div className="user-bio-list">
                  <img src={bagIcon} alt="" />
                  <span>{candidate.experience_status}</span>
                </div>
                <div className="user-bio-list">
                  <img src={walletIcon} alt="" />
                  <span>₹ {candidate?.annual_salary}</span>
                </div>
                <div className="user-bio-list">
                  <img src={mapIcon} alt="" />
                  <span>
                    {candidate?.city}, {candidate?.state}
                  </span>
                </div>
              </div>
            </div>
            <div className="user-bio-info">
              <div className="user-bio-labels">
                <div className="bio-item">
                  <img src={bookIcon} alt="" />
                  <span>Education : </span>
                  <span className="user-bio-values">
                    {candidate?.qualification}
                  </span>
                </div>
                <div className="bio-item">
                  <img src={bilidingIcon} alt="" />
                  <span>Current Company : </span>
                  <span className="user-bio-values">
                    {candidate?.current_company}
                  </span>
                </div>
                <div className="bio-item">
                  <img src={mapIcon} alt="" />
                  <span>Preferred Location : </span>
                  <span className="user-bio-values">
                    {candidate?.preffered_location_name?.join(", ")}
                  </span>
                </div>
                <div className="bio-item">
                  <img src={bookIcon} alt="" />
                  <span>Skills : </span>
                  <span className="user-bio-values">
                    {candidate?.skills_name?.join(", ")}
                  </span>
                </div>
              </div>
            </div>
          </>
        }
        className="candidate-card"
      >
        <Flex
          gap="small"
          wrap
          justify="flex-end"
          className="candidate-button-group"
        >
          <Button
            type="default"
            size="large"
            icon={<img src={phone1Icon} alt="icon" />}
            onClick={handlePhoneNumberClick}
          >
            {showPhoneNumber ? candidate.phone_number : "View Phone Number"}
          </Button>
          {/* <Button
            type="default"
            size="large"
            icon={<img src={mail1Icon} alt="icon" />}
          >
            View Email
          </Button> */}
          <Button
            type="default"
            size="large"
            icon={<img src={Downolad1Icon} alt="icon" />}
            onClick={() => {
              setIsCvDownloadModalOpen(true);
            }}
          >
            Download CV
          </Button>
        </Flex>
        <Flex justify="space-between" align="center" className="card-footer">
          <ul>
            {/* <li>
              <img src={UnlockIcon} alt="" /> 50 unlocks
            </li> */}
            {/* <li>
              <img src={UnlockIcon} alt="" /> Profile unlocked by me,{" "}
              {candidate.lastActive}
            </li> */}
          </ul>
          <ul style={{ flexWrap: "nowrap" }}>
            {candidate?.cv && (
              <li>
                <img src={FileUploadIcon} alt="" /> CV attached
              </li>
            )}
            {/* <li>
              <img src={ClockIcon} alt="" /> Active on{" "}
              {candidate.lastActiveDate}
            </li> */}
            <li>
              <Button
                type="link"
                block
                icon={<img src={CommentPlusIcon} alt="icon" />}
                onClick={() => {
                  setShowCommentModal(true);
                }}
              >
                Comment
              </Button>
            </li>
          </ul>
        </Flex>
      </Card>
      <ConfirmationModal
        isOpen={isCvDownloadModalOpen}
        onConfirm={() => {
          handleDownloadCV();
        }}
        onCancel={() => setIsCvDownloadModalOpen(false)}
        loading={loading}
        title="Confirm Download"
        message="Are you sure you want to download this candidate's CV?"
      />

      <Modal
        title={`Comment for ${candidate.name}`}
        centered
        open={showCommentModal}
        onCancel={handleCloseCommentModal}
        footer={[
          <Button
            key="cancel"
            className="create-modal-cancel-button"
            onClick={() => {
              setShowCommentModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="confirm"
            className="create-modal-confirm-button"
            onClick={handlePostComment}
            loading={loading}
            disabled={loading || !commentText}
          >
            Post
          </Button>,
        ]}
      >
        <Input.TextArea
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder="Write your comment here..."
          autoSize={{ minRows: 3, maxRows: 8 }}
        />
      </Modal>

      {/* <ToastContainer /> */}
    </>
  );
};

export default CandidateCard;
