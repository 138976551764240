import React, { useEffect, useState } from "react";
import "../styles/Login.css";
import {
  loginBackground,
  loginSlide1,
  loginSlide2,
  loginSlide3,
  swagathamLogo,
} from "../assets";
import LoginSlide from "../components/login/LoginSlide";
// import { post } from "../lib/Api";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Toast from "../components/Toast/Toast";
import { post } from "../lib/Api";

const Login = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const slides = [
    {
      image: loginSlide1,
      heading: "Secure Login",
      description: "Login confidently with our advanced encryption security.",
      loadingDivCount: 3,
      activeCircleIndex: 1,
    },
    {
      image: loginSlide2,
      heading: "Unlock Swagatham with a Tap",
      description:
        "Enjoy seamless login for Swagatham: Password-Free, Simplified Security",
      loadingDivCount: 3,
      activeCircleIndex: 2,
    },
    {
      image: loginSlide3,
      heading: "Seamless Access, Securely",
      description: "Simplify access without risking your security.",
      loadingDivCount: 3,
      activeCircleIndex: 3,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${loginBackground})`,
  };

  const onLogin = async () => {
    const data = { username: email, password: password };
    setLoading(true);
    try {
      const res = await post(`user/user-login`, data, {}, false);
      if (res?.status) {
        localStorage.setItem("__user_token__", res.data.token);
        localStorage.setItem("__user_type__", res.data.user_type);
        localStorage.setItem("__customer_name__", res.data.customer_name);
        localStorage.setItem("__user_name__", res.data.name);

        Toast({ message: "You are successfully loged in", type: "success" });

        // setTimeout(() => {
        navigate("/jobs");
        // }, 2000);
      }else {
        Toast({ message: res.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({ message: "Network Error...", type: "error" });
    }
  };

  return (
    <div className="background-image" style={backgroundImageStyle}>
      <div className="container">
        <div className="left-panel">
          <div>
            <img src={swagathamLogo} alt="Company Logo" className="logo" />
          </div>
          <div className="heading">Login</div>
          <div className="input-group">
            <label>Email</label>
            <div className="input-with-icon">
              <input
                type="text"
                className="input"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <i className="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
          </div>
          <div className="input-group" style={{ marginTop: "20px" }}>
            <label>Password</label>
            <div className="input-with-icon">
              <i className="fa fa-lock" aria-hidden="true"></i>
              <input
                type={passwordVisible ? "text" : "password"}
                className="input"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}
                aria-hidden="true"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          </div>
          <div>
            <button className="button" onClick={onLogin} disabled={loading}>
              {loading ? "Loading..." : "Login"}
            </button>
          </div>
          <Link to="/forgotpassword" style={{ textDecoration: 'none' }}>
            <div className="forgot-password">
              <span>Forgot password?</span>
            </div>
          </Link>
          {/* <div className="contact-admin">
            Having issues with your login? <span>Contact Admin</span>
          </div> */}
        </div>
        <LoginSlide {...slides[currentSlide]} />

        <ToastContainer />
      </div>
    </div>
  );
};

export default Login;
